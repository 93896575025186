import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'

import { Links } from './index.style'
import { refLinksConfig } from './config/config'

const MoleculeLinks = ({
  onLinkActionClick,
  referencesCount,
  isBestMatch,
  isDialog,
  isMoleculeViewer,
}) => {
  const { t } = useTranslation()
  return (
    <Links isBestMatch={isBestMatch}>
      {refLinksConfig.map(({ name, disabled, handler }, i) => (
        <CustomButton
          type={isDialog ? 'secondary' : isMoleculeViewer ? 'text' : 'primary'}
          key={i}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation()
            onLinkActionClick(handler)
          }}
          {...(name === 'molviewer.to_reactions' && {
            style: { visibility: 'hidden' },
          })}
        >
          {name === 'molviewer.literature' && !!referencesCount
            ? `${t(name)} (${referencesCount})`
            : t(name)}{' '}
          <Icon iconType="arrowRight" size="1rem" />
        </CustomButton>
      ))}
    </Links>
  )
}
export default MoleculeLinks
