import { Subheadline } from 'components/common/text/index.style'
import styled from 'styled-components'

export const CardWrapper = styled.div`
  padding: ${({ padding }) => (padding ? padding : '20px')};
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '12px')};
  position: relative;
  border-radius: 12px;
  background: white;
  cursor: ${({ isFullMode }) => (isFullMode ? 'default' : 'pointer')};
  max-height: ${({ showMore, showMoreAvailable }) =>
    showMore ? 'none' : showMoreAvailable ? '31rem' : '28.5rem'};
  transition: max-height 0.3s ease-in-out;
`

export const CardContent = styled.div`
  position: relative;
  overflow: hidden;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 95%;
  max-height: ${({ maxHeight }) => maxHeight && maxHeight};
  padding: ${({ padding }) => padding && padding};
  width: ${({ width }) => width && width};
`

export const CardReaction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: default;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
`
export const CardMethodsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-gap: 0.5rem;
  margin-top: 1rem;
`
export const ShowMoreBlock = styled.div`
  position: relative;
  left: ${({ left }) => (left ? left : '0.5rem')};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justif-content: center;
  margin-right: auto;
`

export const CardMethod = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  border-radius: 0.375rem;
  padding: 1rem;
  height: 13.5rem;
  max-height: 13.5rem;
`
export const MethodFlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const MethodYield = styled.div`
  display: flex;
  align-items: center;
  justify-content: space between;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.buttons.primary};
  border-radius: 0.375rem;
  padding: 0.375rem 0.5rem;
  height: 1.5rem;
`

export const SourcesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space between;
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
export const TypeRow = styled(Row)`
  & > svg:not(.copy-name-icon) {
    color: ${({ theme }) => theme.colors.statuses.link};
    cursor: pointer;
  }
  .copy-name-icon {
    color: ${({ theme }) => theme.colors.icons.tertiary};

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
  flex-wrap: ${({ flexWrap }) => flexWrap && flexWrap};
  overflow: ${({ overflow }) => overflow && overflow};
  height: ${({ height }) => height && height};
`

export const TypeNumber = styled(Subheadline)`
  color: ${({ theme, isLink }) =>
    isLink ? theme.colors.statuses.link : theme.colors.text.primary};
  text-decoration: none;
  width: ${({ width }) => width && width};
  & a {
    color: inherit;
    text-decoration: inherit;
  }
`

export const Option = styled(Row)`
  color: ${({ disabled }) => (disabled ? '#CED6E3' : '#2D3745')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled }) => (disabled ? '#CED6E3' : '#42c1bf')};
  }
`

export const DialogCardAbstract = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #6e809d;
  cursor: inherit;
`

export const CardAbstract = styled(DialogCardAbstract)`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '1rem')};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamp }) => (lineClamp ? lineClamp : '1')};
  -webkit-box-orient: vertical;
`

export const Container = styled.div`
  display: flex;
  row-gap: 1rem;
  height: 10rem;
`

export const ReactBlock = styled.div`
  align-items: center;
  justify-content: start;
  width: 100%;
  row-gap: 1rem;
  display: flex;
  position: relative;
`
export const SvgWrapper = styled.div`
  width: ${({ size }) => (size ? size : '1.25rem')};
  min-width: ${({ size }) => (size ? size : '1.25rem')};
`

export const Source = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`

export const SourceLink = styled.a`
  text-overflow: ellipsis;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  text-wrap: nowrap;
  width: ${({ width }) => width && width};
  white-space: ${({ whiteSpace }) => whiteSpace && whiteSpace};
  word-break: nobreak;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.25rem;
`

export const ElementsWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 0.25rem;
  width: 100%;
`
