import { v4 as uuidv4 } from 'uuid'
import * as FileSaver from 'file-saver'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { withResizeDetector } from 'react-resize-detector'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useMemo, useCallback } from 'react'

import {
  copyBasket,
  loadBaskets,
  joinBaskets,
  deleteBasket,
  updateBasket,
  loadLastChange,
} from 'store/actions/crud'
import {
  setEditId,
  setEditMode,
  setSelected,
  selectBasket,
  setBasketDesc,
  setBasketName,
  setShowAddDialog,
  setShowLogDialog,
  setShowJoinCopyDialog,
  setBasketsSortingParam,
  setBasketsSearchName,
  checkCustomerDatasetsAccess,
  handleTransferToCustomerDatasets,
} from 'store/actions/basket'
import { confirm } from 'store/actions/confirm'
import { addToMMap, deleteLayer } from 'store/actions/mmap'
import { setBasketType } from 'store/actions/settings'
import { addNotification } from 'store/actions/notifications'

import { useAuth } from 'utils/auth/auth'
import { fetch, store } from 'services/rest'

import Icon from 'components/Icon'
import Basket from 'components/Basket'
import AddEditDialog from 'components/AddEditDialog'
import CustomSnackbar from 'components/HotTip/index'
import CustomScrollbar from 'components/CustomScrollbar'
import BasketLogDialog from 'components/BasketLogDialog'
import SkeletonComponent from 'components/common/skeleton'
import ExportBasketDialog from 'components/ExportBasketDialog'
import BasketsTopToolbar from 'components/BasketsTopToolbarRestyled'
import BasketJoinAndCopyDialog from 'components/BasketJoinAndCopyDialog'
import { Caption, TitleSecondary } from 'components/common/text/index.style'

import {
  Content,
  Toolbar,
  BasketList,
  Container,
  IconWrapper,
  EmptyBasket,
  BasketListWrapper,
  HeaderWithToolbar,
  DatasetTypeSelectors,
  SearchWithSort,
  NothingFoundText,
} from './index.style'
import {
  BASKET_HEIGHT,
  BASKET_TYPES,
  BASKET_WIDTH,
  basketsSortConfig,
  getBasketsTopToolbarConfig,
} from './config/config'
import CustomTab from 'components/common/customTab'
import usePrevious from './lib/usePrevious'
import SortPanel from 'components/SortPanel'
import CustomInput from 'components/common/customInput'
import { readStorage } from 'utils/storage/storage'

const Baskets = ({
  loadBaskets,
  baskets,
  loadLastChange,
  lastChange,
  deleteBasket,
  copyBasket,
  joinBaskets,
  updateBasket,
  push,
  addToMMap,
  error,
  loading,
  lastChangeLoading,
  confirm,
  basketType,
  deleteLayer,
  addNotification,
  selectBasket,
  width,
  height,
  sortType,
  sortDirection,
  setBasketsSortingParam,
  basketsSearchName,
  setBasketsSearchName,
  isBasketsAfterSearch,
  setBasketType,
  checkCustomerDatasetsAccess,
  handleTransferToCustomerDatasets,
}) => {
  const dispatch = useDispatch()
  const {
    name,
    showAddDialog,
    desc,
    editMode,
    editId,
    showLogDialog,
    selected,
    showJoinCopyDialog,
  } = useSelector((state) => state.basket)

  const [isSelectingNow, setIsSelectingNow] = useState(false)
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [skeletonItemsCount, setSkeletonItemsCount] = useState(1)

  const tasksStatus = useSelector((state) => state.tasks)
  const prevTasksStatus = usePrevious(tasksStatus)

  const { isAuthenticated } = useAuth()
  const { t } = useTranslation()
  const theme = useTheme()

  const handleRefresh = useCallback(() => {
    if (!basketType) return
    loadBaskets({ basketType })
    if (isAuthenticated) loadLastChange()
  }, [basketType, isAuthenticated, loadBaskets, loadLastChange])

  useEffect(() => {
    if (!basketType) return
    loadBaskets({ basketType })
    if (isAuthenticated) loadLastChange()
  }, [isAuthenticated, loadBaskets, loadLastChange, basketType])

  useEffect(() => {
    if (basketsSearchName?.length && basketsSearchName.length < 3) {
      setBasketsSearchName('')
    }
    checkCustomerDatasetsAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      tasksStatus &&
      prevTasksStatus &&
      tasksStatus.length !== prevTasksStatus.length
    ) {
      handleRefresh()
    }
  }, [handleRefresh, prevTasksStatus, tasksStatus])

  const checkIsSelecting = (e) => {
    if (e.key === 'Control') {
      setIsSelectingNow(e.type === 'keydown')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', checkIsSelecting)
    window.addEventListener('keyup', checkIsSelecting)

    return () => {
      window.removeEventListener('keydown', checkIsSelecting)
      window.removeEventListener('keyup', checkIsSelecting)
    }
  }, [])

  useEffect(() => {
    if (basketType === 'private' && sortType === 'relevance') {
      setBasketsSortingParam('type', 'date')
      setBasketsSortingParam('direction', 'desc')
      loadBaskets({ basketType })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketType])

  const handleDelete = () => {
    const list = [...selected]
    confirm(t('confirmation.areYouSure'), () => {
      list.forEach((el) => deleteLayer(el))
      deleteBasket(list)
      dispatch(setSelected([]))
    })
  }

  const handleCopy = (data) => {
    const { new_basket_name, new_basket_desc } = data || {}
    const basket_id = selected?.[0]
    copyBasket({ basket_id, new_basket_name, new_basket_desc })
    dispatch(setSelected([]))
    handleCloseJoinCopyDialog()
  }

  const handleAdd = () => {
    dispatch(setShowAddDialog(true))
  }

  const handleTransfer = useCallback(() => {
    const basket_ids = [...selected]
    handleTransferToCustomerDatasets({ basket_ids, basketType })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketType, selected])

  const handleOpenJoinCopyDialog = (value) => {
    !showJoinCopyDialog && dispatch(setShowJoinCopyDialog(value))
  }
  const handleJoin = (data) => {
    const { new_basket_name, new_basket_desc } = data || {}
    const basket_ids = [...selected]
    joinBaskets({ basket_ids, new_basket_name, new_basket_desc })
    handleCloseJoinCopyDialog()
  }

  const handleCloseJoinCopyDialog = () => {
    dispatch(setShowJoinCopyDialog(null))
    dispatch(setSelected([]))
  }

  const handleCloseAddDialog = () => {
    dispatch(setShowAddDialog(false))
    dispatch(setBasketName(''))
    dispatch(setBasketDesc(''))
    dispatch(setEditId(null))
    dispatch(setEditMode(false))
  }

  const closeWithSave = async (name, desc) => {
    if (editMode) {
      updateBasket(editId, name, desc)
    } else {
      try {
        const { data } = await store('/baskets', {
          name,
          desc,
          ...(basketType === 'companies' ? { by_customer: true } : {}),
        })

        selectBasket({ id: data.id, name, info: desc })
        push(`/molecules/${data.id}`)
      } catch (e) {
        const errorText =
          e?.response?.data?.result?.error_message ===
          'You must be a company admin to perform this action.'
            ? 'notification.you_must_be_admin'
            : 'account.somethingWentWrong'
        const notify = {
          id: uuidv4(),
          name: 'notification.dataset_add_error',
          notification_type: 'error',
          autoRemove: true,
          text: errorText,
          needTranslateText: true,
        }
        addNotification(notify)
      }
    }
    handleCloseAddDialog()
  }

  const handleCloseLogDialog = () => {
    setEditId(null)
    dispatch(setShowLogDialog(false))
  }

  const handleAddToMap = async () => {
    try {
      const { data } = await store('/baskets_info', {
        basket_ids: [...selected],
      })
      selected.forEach((basketId) => {
        const line = data.find(({ id }) => id === basketId)
        addToMMap({ set: basketId }, `Set: ${line?.name ?? 'unnamed'}`)
      })
    } catch (e) {
      console.log(e)
    }

    dispatch(setSelected([]))
    push('/synmap')
  }

  const handleCalculate = async () => {
    try {
      const basket = selected[0]
      await fetch(`/calc_basket?basket_id=${basket}`)
      dispatch(setSelected([]))
      handleRefresh()
    } catch (e) {
      console.log(e)
    }
  }

  const handleDownload = async (format) => {
    setShowExportDialog(false)
    const id = uuidv4()
    const notify = {
      id,
      name: 'baskets.export_in_progress',
      text: 'baskets.export_in_progress_small',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }

    addNotification(notify)

    try {
      dispatch(setSelected([]))
      const { data } = await fetch(
        `/download_basket?type=${format}&basket_id=${selected[0]}`
      )

      const blob = new Blob([data], { type: 'application/json' })

      FileSaver.saveAs(blob, `syntelly-${selected[0]}.${format}`)
      if (data) {
        const notify = {
          id,
          name: 'baskets.export_success',
          text: 'baskets.export_success',
          notification_type: 'success',
          autoRemove: true,
          timeout: 5000,
        }
        addNotification(notify)
      }
    } catch (e) {
      console.log(e)
      const notify = {
        id,
        name: 'baskets.export_error',
        text: 'baskets.export_error',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
    }
  }

  const showCustomerDatasets = () => {
    const res = readStorage('showCustomerDatasets')
    return res ?? false
  }

  const calculateForExport = async () => {
    try {
      const basket = selected[0]
      await fetch(`/calc_basket?basket_id=${basket}`)
      handleRefresh()
      return true
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: 'baskets.failed_to_calculate_dataset',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }

      addNotification(notify)
      return false
    }
  }

  const usedBasketsNames = useMemo(() => {
    return baskets.map((basktet) => basktet.name)
  }, [baskets])
  const buttonsConfig = getBasketsTopToolbarConfig({
    showCustomerDatasets: showCustomerDatasets(), //d useMemo???
    isPrivateDataset: basketType === 'private',
    len: selected.length,
    isEmptyBasketSelected:
      selected.length === 1 &&
      baskets.find((el) => el?.id === selected[0])?.count === 0,
    onAdd: handleAdd,
    onCopy: () => handleOpenJoinCopyDialog('copy'),
    onDelete: handleDelete,
    onJoin: () => handleOpenJoinCopyDialog('join'),
    onAddToMap: handleAddToMap,
    onRefresh: handleRefresh,
    onCalculate: handleCalculate,
    // onUndo: handleUndo, //заменен на хук useUndo
    isLogin: isAuthenticated,
    lastChange: lastChange,
    onExport: () => setShowExportDialog(true),
    onTransfer: handleTransfer,
  })

  const selectBaskets = (id, toIdx) => {
    let res
    if (selected.length === 0) {
      res = baskets
        .filter((bask, idx) => idx <= toIdx && bask?.status !== 'pending')
        .map(({ id }) => id)
    } else {
      const start = selected.pop()
      const fromIdx = baskets.findIndex((b) => b.id === start)
      let list = baskets
        .filter(
          (bask, idx) =>
            idx <= Math.max(toIdx, fromIdx) &&
            idx >= Math.min(toIdx, fromIdx) &&
            bask?.status !== 'pending'
        )
        .map((e) => e.id)
      res = [...new Set([...selected, ...list])]
    }

    dispatch(setSelected([...res]))
  }

  const handleChangeSorting = (type, value) => {
    if (type === 'direction' && value === sortDirection) return
    setBasketsSortingParam(type, value)
    if (type === 'type' && value === 'relevance' && sortDirection === 'asc') {
      setBasketsSortingParam('direction', 'desc')
    }
    basketType && loadBaskets({ basketType })
  }
  // реагируем на ввод от трех символов
  const onChangeBasketsSearchName = (name) => {
    setBasketsSearchName(name)
    if (!name || name.length >= 3) {
      basketType && loadBaskets({ basketType })
    }
  }

  useEffect(() => {
    if (error && !error?.response?.data?.detail?.includes('pending')) {
      const id = uuidv4()

      const notify = {
        id,
        name: 'spectra.error.error',
        text: error.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }

      addNotification(notify)
    }
  }, [addNotification, error, t])

  useEffect(() => {
    if (width && height) {
      setSkeletonItemsCount(
        Math.floor(width / (BASKET_WIDTH + 12)) *
          Math.floor((height - 124) / (BASKET_HEIGHT + 8))
      )
    }
  }, [width, height])

  return (
    <Container>
      <Content>
        <HeaderWithToolbar>
          <TitleSecondary>{t('baskets.toplabel')}</TitleSecondary>
          <Toolbar>
            <DatasetTypeSelectors>
              {BASKET_TYPES.map((name) => (
                <CustomTab
                  onClick={() => {
                    setBasketType(name)
                    name !== basketType && dispatch(setSelected([]))
                  }}
                  type="white"
                  key={name}
                  isActive={basketType === name}
                  disabled={name === 'companies' && !showCustomerDatasets()}
                >
                  {t(`baskets.dataset_type_selectors.${name}`)}
                </CustomTab>
              ))}
            </DatasetTypeSelectors>
            {!!baskets?.length && basketType !== 'thematic' && (
              <BasketsTopToolbar config={buttonsConfig} />
            )}
          </Toolbar>
          {(!!baskets?.length || isBasketsAfterSearch || loading) && (
            <SearchWithSort>
              <CustomInput
                value={basketsSearchName}
                onChange={onChangeBasketsSearchName}
                withClearButton
                withWhiteBackground
                placeholder={t('baskets.enter_dataset_name')}
              />
              <SortPanel
                {...{ sortType }}
                onChangeSorting={handleChangeSorting}
                sortOptions={basketsSortConfig(basketType === 'private')}
                switcherID="basketsDirection"
              />
            </SearchWithSort>
          )}
        </HeaderWithToolbar>
        {(loading || lastChangeLoading) && (
          <BasketListWrapper>
            <CustomScrollbar>
              <BasketList>
                {[...Array(skeletonItemsCount)].map((_, index) => (
                  <SkeletonComponent
                    key={index}
                    width="100%"
                    minWidth="15.875rem"
                    height="11.5rem"
                    variant="dark"
                  />
                ))}
              </BasketList>
            </CustomScrollbar>
          </BasketListWrapper>
        )}
        {!!baskets?.length && (
          <BasketListWrapper onClick={() => dispatch(setSelected([]))}>
            <CustomScrollbar>
              <BasketList>
                {baskets.map((bask, index) => (
                  <Basket
                    key={bask.id}
                    tasksStatus={tasksStatus}
                    bask={bask}
                    push={push}
                    isSelectingNow={isSelectingNow}
                    selectBaskets={() => selectBaskets(bask.id, index)}
                  />
                ))}
              </BasketList>
            </CustomScrollbar>
          </BasketListWrapper>
        )}
        {!baskets?.length &&
          (!isBasketsAfterSearch ? (
            <EmptyBasket onClick={handleAdd}>
              <IconWrapper>
                <Icon iconType="add" size="1rem" />
              </IconWrapper>
              <Caption
                fontWeight={theme.fontWeight.medium}
                lineHeight={theme.text[14]}
                color="inherit"
              >
                {t('moleculeslist.add_dataset')}
              </Caption>
            </EmptyBasket>
          ) : (
            <NothingFoundText>
              {t('dataset_table.nothing_found')}
            </NothingFoundText>
          ))}
      </Content>
      {showJoinCopyDialog && (
        <BasketJoinAndCopyDialog
          type={showJoinCopyDialog ?? 'join'}
          handleJoin={handleJoin}
          handleCopy={handleCopy}
          handleClose={handleCloseJoinCopyDialog}
          usedNames={usedBasketsNames?.filter((n) => n !== name)}
        />
      )}
      {showAddDialog && (
        <AddEditDialog
          closeWithSave={closeWithSave}
          editMode={editMode}
          handleCloseAddDialog={handleCloseAddDialog}
          name={name}
          desc={desc}
          usedNames={usedBasketsNames?.filter((n) => n !== name)}
        />
      )}
      {showLogDialog && (
        <BasketLogDialog handleClose={handleCloseLogDialog} id={editId} />
      )}
      {showExportDialog && selected.length === 1 && (
        <ExportBasketDialog
          basketName={baskets.find((b) => b.id === selected[0])?.name ?? ''}
          handleDownload={handleDownload}
          handleClose={() => setShowExportDialog(false)}
          calculateForExport={calculateForExport}
          tasksStatus={tasksStatus}
          bask={baskets.find((b) => b.id === selected[0])}
        />
      )}

      {!(loading || lastChangeLoading) && <CustomSnackbar />}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    baskets: state.crud.baskets,
    searchedBaskets: state.crud.searchedBaskets,
    lastChange: state.crud.lastChange,
    error: state.crud.error,
    loading: state.crud.loading,
    lastChangeLoading: state.crud.lastChangeLoading,
    basketType: state.settings.basketType,
    sortType: state.basket.sorting.type,
    sortDirection: state.basket.sorting.direction,
    basketsSearchName: state.basket.searchName,
    isBasketsAfterSearch: state.crud.isBasketsAfterSearch,
  }
}

const mapDispatchToProps = {
  push,
  loadBaskets,
  loadLastChange,
  deleteBasket,
  deleteLayer,
  copyBasket,
  joinBaskets,
  updateBasket,
  addToMMap,
  confirm,
  addNotification,
  selectBasket,
  setBasketsSortingParam,
  setBasketsSearchName,
  setBasketType,
  checkCustomerDatasetsAccess,
  handleTransferToCustomerDatasets,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResizeDetector(Baskets))
