import {
  MMAP_ADD_GEN,
  MMAP_ADD_GENERATOR,
  MMAP_ADD_OPTIONS,
  MMAP_CHANGE_OPTIONS,
  MMAP_CLEAR,
  MMAP_CREATE_GENERATOR_LAYER,
  MMAP_DELETE_GEN,
  MMAP_DELETE_LAYER,
  MMAP_LOAD_FAIL,
  MMAP_LOAD_INIT,
  MMAP_LOAD_SUCCESS,
  MMAP_UPDATE_GENERATOR,
  MMAP_ADD_DATASET,
  MMAP_DELETE_DATASET,
  MMAP_CLEAR_DATASET,
  MMAP_TOGGLE_IS_3D_MAP_MODE,
  MMAP_3D_LOAD_INIT,
  MMAP_3D_DELETE_LAYER,
  MMAP_3D_LOAD_SUCCESS,
  MMAP_3D_LOAD_INIT_FETCHING,
  MMAP_3D_LOAD_FAIL,
  MMAP_SET_SELECTED,
  MMAP_CHANGE_GENERATOR_STATUS,
  MMAP_CLEAR_SELECTED_AREA,
  MMAP_SET_SELECTED_AREA,
  SET_GEN_PROPS,
  CLEAR_GEN_PROPS,
  SET_OPT_PROPS,
  CLEAR_OPT_PROPS,
  GENERATOR_CALC_INIT,
  MMAP_ADD_FAIL,
} from '../constants/mmap'

export const initialState = {
  layers: [],
  options: [],
  selectedDatasets: [],
  generators: [],
  loading: false,
  error: false,
  is3dMapMode: false,
  layers3d: [],
  selected: [],
  selectedArea: null,
  generationProps: [],
  optimizationProps: [],
}

const mmapReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_GEN_PROPS:
      return { ...state, generationProps: [...payload.generationProps] }
    case CLEAR_GEN_PROPS:
      return { ...state, generationProps: [] }
    case SET_OPT_PROPS:
      return { ...state, optimizationProps: [...payload.optimizationProps] }
    case CLEAR_OPT_PROPS:
      return { ...state, optimizationProps: [] }
    case MMAP_ADD_GEN:
      return {
        ...state,
        generators: [...state.generators, payload.generator],
        //generator creation is disabled in 2d
      }

    case MMAP_DELETE_LAYER: {
      const delIndex = state.options.findIndex(
        ({ datasetId, id }) => id === payload.id || datasetId === payload.id
      )

      if (delIndex < 0) return state

      const foundDatasetId = state.options[delIndex].datasetId

      const newLayers3d = state.layers3d.filter(
        ({ datasetId }) => datasetId !== foundDatasetId
      )
      const newOpts = state.options.filter((_, idx) => delIndex !== idx)
      const newLayers = state.layers.filter((_, idx) => delIndex !== idx)

      return {
        ...state,
        layers: newLayers,
        options: newOpts,
        layers3d: newLayers3d,
      }
    }

    case MMAP_DELETE_GEN: {
      const delIndex = state.options.findIndex(({ id }) => id === payload.token)
      const newLayers = state.layers.filter(
        (el, id) => el.length > 0 && id !== delIndex
      )
      const newOpts = state.options.filter((el) => el.id !== payload.token)
      const newGeneratorList = state.generators.filter(
        (el) => el.token !== payload.token
      )
      const layers3d = state.layers3d.filter(
        ({ generatorId }) => generatorId !== payload.token
      )

      return {
        ...state,
        generators: newGeneratorList,
        layers: newLayers,
        options: newOpts,
        layers3d,
      }
    }

    case MMAP_CHANGE_GENERATOR_STATUS: {
      const newGeneratorList = state.generators.map((el) =>
        el.token !== payload.token ? el : { ...el, status: payload.status }
      )

      return {
        ...state,
        generators: newGeneratorList,
      }
    }

    case MMAP_CLEAR:
      return {
        ...state,
        layers: [],
        options: [],
        selectedDatasets: [],
        generators: [],
        layers3d: [],
        selected: [],
        selectedArea: null,
      }
    case MMAP_LOAD_INIT:
      return { ...state, layers: [], loading: true, error: false }
    case MMAP_ADD_OPTIONS:
      return {
        ...state,
        layers: [...state.layers, []],
        options: [...state.options, payload.options],
        loading: false,
        error: false,
      }
    case MMAP_LOAD_SUCCESS: {
      const idx = state.options.findIndex(
        ({ id }) => id === payload?.options?.id
      )
      if (idx >= 0) {
        return {
          ...state,
          layers: state.layers.map((l, i) => {
            if (idx !== i) return l
            return payload.layer
          }),
          options: state.options.map((l, i) => {
            if (idx !== i) return l
            return { ...l, loading: false }
          }),
          is3dMapMode: false,
          layers3d: [
            ...state.layers3d,
            {
              datasetId: payload.datasetId,
              status: 'new',
            },
          ],
        }
      }
      return state
    }

    case MMAP_ADD_FAIL: {
      const idx = state.options.findIndex(({ id }) => id === payload?.id)
      if (idx >= 0) {
        return {
          ...state,
          layers: state.layers.filter((_, i) => idx !== i),
          options: state.options.filter((_, i) => idx !== i),
          is3dMapMode: false,
        }
      }
      return state
    }

    case MMAP_CREATE_GENERATOR_LAYER: {
      return {
        ...state,
        layers: [...state.layers, []],
        options: [...state.options, payload.options],
        loading: false,
        error: false,
        layers3d: [
          ...state.layers3d,
          {
            generatorId: payload.options.id,
            status: 'new',
          },
        ],
      }
    }

    case MMAP_UPDATE_GENERATOR: {
      return {
        ...state,
        layers: state.layers.map((el, idx) =>
          idx !== payload.idx ? el : payload.layer
        ),
        options: state.options.map((el, idx) =>
          idx !== payload.idx
            ? el
            : { ...el, epoch: payload.iter, status: payload.status }
        ),
        generators: state.generators.map((el) => {
          if (el.token !== payload.token) return el
          return {
            ...el,
            progress: payload.progress,
            status: payload.status,
          }
        }),
      }
    }
    case MMAP_ADD_GENERATOR: {
      const idx = state.options.findIndex(
        ({ generatorTask }) => generatorTask === payload.taskid
      )

      return {
        ...state,
        layers: state.layers.map((l, i) => {
          if (idx !== i) return l
          return l.data
        }),
        options: state.options.map((l) => {
          if (l.generatorTask !== payload.taskid) return l
          return { ...l, epoch: payload.epoch }
        }),
      }
    }
    case MMAP_LOAD_FAIL:
      return { ...state, layers: [], loading: false, error: true }

    case MMAP_CHANGE_OPTIONS:
      return {
        ...state,
        options: payload.opt,
      }

    case MMAP_ADD_DATASET:
      return {
        ...state,
        selectedDatasets: [...state.selectedDatasets, payload.id],
      }

    case MMAP_DELETE_DATASET: {
      const newDatasetIdArray = state.selectedDatasets.filter(
        (datasetId) => datasetId !== payload.id
      )
      return {
        ...state,
        selectedDatasets: newDatasetIdArray,
      }
    }

    case MMAP_CLEAR_DATASET: {
      return { ...state, selectedDatasets: [] }
    }

    case MMAP_TOGGLE_IS_3D_MAP_MODE: {
      return { ...state, is3dMapMode: !state.is3dMapMode }
    }

    case MMAP_3D_LOAD_INIT: {
      return {
        ...state,
        layers3d: state.layers3d.map((layer) => {
          const found = payload.layers3d.find(({ datasetId, generatorId }) =>
            datasetId
              ? datasetId === layer.datasetId
              : generatorId === layer.generatorId
          )

          return found
            ? {
                ...layer,
                status: 'progress',
                task_uuid: found.task_uuid,
              }
            : layer
        }),
      }
    }

    case MMAP_3D_LOAD_INIT_FETCHING: {
      return {
        ...state,
        layers3d: state.layers3d.map((layer) => {
          const found = payload.layers3d.find(({ datasetId, generatorId }) =>
            datasetId
              ? datasetId === layer.datasetId
              : generatorId === layer.generatorId
          )

          return found
            ? {
                ...layer,
                status: 'loading',
              }
            : layer
        }),
      }
    }

    case MMAP_3D_LOAD_FAIL: {
      return {
        ...state,
        is3dMapMode: false,
        layers3d: state.layers3d.map((layer) => {
          const found = payload.layers3d.find(({ datasetId, generatorId }) =>
            datasetId
              ? datasetId === layer.datasetId
              : generatorId === layer.generatorId
          )

          return found
            ? {
                ...layer,
                status: 'new',
              }
            : layer
        }),
      }
    }

    case MMAP_3D_LOAD_SUCCESS: {
      return {
        ...state,
        layers3d: state.layers3d.map((layer) => {
          return layer.task_uuid === payload.task_uuid
            ? {
                ...layer,
                status: 'done',
                data: payload.data,
              }
            : layer
        }),
      }
    }

    case MMAP_3D_DELETE_LAYER: {
      return {
        ...state,
        layers3d: state.layers3d.filter(
          (layer) => layer.task_uuid !== payload.task_uuid
        ),
        is3dMapMode: state.layers3d.length < 2 ? false : true,
      }
    }

    case MMAP_SET_SELECTED: {
      return { ...state, selected: payload.arr }
    }
    case MMAP_SET_SELECTED_AREA: {
      return { ...state, selectedArea: payload.data }
    }
    case MMAP_CLEAR_SELECTED_AREA: {
      return { ...state, selectedArea: null }
    }
    case GENERATOR_CALC_INIT: {
      return { ...state }
    }

    default:
      return state
  }
}

export default mmapReducer
