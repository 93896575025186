import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import Papa from 'papaparse'
import { useTheme } from 'styled-components'

import Icon from 'components/Icon'
import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import { addNotification } from 'store/actions/notifications'
import {
  Caption,
  Subheadline,
  TitleTertiary,
} from 'components/common/text/index.style'
import './index.css'

import {
  Content,
  Wrapper,
  FileInfo,
  FileInput,
  KetcherBlock,
  SelectWithLabel,
} from './index.style'
import Ketcher from '../Ketcher'
import InputHelper from '../InputHelper'
import { getIcon, getOptions } from './utils'
import { MAX_FILE_SIZE, SMILES_SIZE, delimiterOptionsConfig } from './config'
import {
  validateDelimiterSymbols,
  validateEnglishInput,
} from 'utils/common/common'
import CustomRadioList from 'components/Filter/components/CustomRadioList'
import { useDebounce } from 'hooks/useDebounce'
import MoleculeStructure from 'components/MoleculeStructure'

const MoleculeAddViewRestyled = ({
  noFiles,
  handleClose,
  handleLoadFile,
  addNotification,
  handleMultiSmiles,
  withPortal = false,
}) => {
  const optionsForRender = getOptions(noFiles).filter((el) => !el?.hidden)
  const { t } = useTranslation()
  const [addType, setAddType] = useState(optionsForRender[0])

  const nameRef = useRef()
  const theme = useTheme()

  const [showKetcher, setShowKetcher] = useState(false)
  const [smiles, setSmiles] = useState('')
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)
  const [listFromFile, setListFromFile] = useState([])
  const [fileTargetHeading, setFileTargetHeading] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [delimiterState, setDelimiterState] = useState(',')

  const [ownDelimiter, setOwnDelimiter] = useState('')
  const debouncedOwnDelimiterValue = useDebounce(ownDelimiter, 500)

  const handlePointerIn = () => setShowOptions(true)
  const handlePointerOut = () => setShowOptions(false)

  const fileIcon = getIcon(
    file?.type || file?.name?.split('.')?.slice(-1)[0] || ''
  )

  const isUploadButtonDisabled =
    !(smiles?.trim() || file) ||
    error ||
    (file?.type?.includes('csv') && !fileTargetHeading)

  useEffect(() => {
    if (error) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.too_big_file',
        text: 'notification.no_more_100_mb',
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }
      addNotification(notify)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    file &&
      parseListFromCsvFile({
        csv_file: file,
        delimiterToUse: debouncedOwnDelimiterValue?.toString(),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOwnDelimiterValue, file])

  const handleChangeSmiles = (smiles) => {
    if (!smiles || validateEnglishInput(smiles)) setSmiles(smiles)
  }

  const parseListFromCsvFile = ({ csv_file, delimiterToUse }) => {
    Papa.parse(csv_file, {
      delimiter:
        delimiterToUse || ownDelimiter?.toString() || delimiterState || ',',
      complete: (results) => {
        const data = results?.data || []
        const list = []
        data?.forEach((el, idx) => idx === 0 && list.push(...el))
        setListFromFile(list)
      },
      error: (err) => {
        setError(true)
      },
    })
  }
  const handleLoad = async (e, file) => {
    setError(false)
    setFileTargetHeading('')
    for (let file of e.target.files) {
      if (file.size > MAX_FILE_SIZE) {
        setError(true)
      }

      const fileReader = new FileReader()
      fileReader.readAsBinaryString(e.target.files[0])
      fileReader.onload = async (event) => {
        const result = event?.target?.result
        const module = await import('xlsx')
        const XLSX = await module.default
        if (file?.type?.includes('csv')) {
          parseListFromCsvFile({ csv_file: result })
        } else {
          const workbook = XLSX.read(result, { type: 'binary' })

          const targetData = workbook.SheetNames[0]

          const data = XLSX.utils.sheet_to_json(workbook.Sheets[targetData], {
            defval: '',
            header: 1,
          })
          const list = []
          data?.forEach((el, idx) => idx === 0 && list.push(...el))
          setListFromFile(list)
        }
      }

      setFile(file)
    }
  }

  const handleSelect = (option) => {
    const value = option.value

    setAddType(option)
    if (value === 'editor') setShowKetcher(true)
    if (value === 'file') {
      setSmiles('')
    }
  }

  const handleLoadtoSyn = () => {
    if (addType.value === 'file') {
      const delimiter = ownDelimiter?.toString() || delimiterState
      handleLoadFile(file, fileTargetHeading, delimiter)
      handleClose()
    } else {
      // 2.07.24 commented out until the inputs are prepared
      //   const validateSmiles = await isValidSMILES(smiles?.toString())
      //   if (!validateSmiles) {
      //     const id = uuidv4()
      //     const notify = {
      //       id,
      //       name: 'notification.error',
      //       notification_type: 'error',
      //       text: 'pdf2smiles.viewer.invalid_smiles',
      //       autoRemove: true,
      //       timeout: 5000,
      //     }
      //     addNotification(notify)
      //     return
      //   }

      const list = [{ smiles }]

      handleMultiSmiles(list)
    }
  }

  return (
    <CustomDialog
      onClose={() => !showKetcher && handleClose()}
      gap="1.5rem"
      width="23.25rem"
      withPortal={withPortal}
      outboundExceptions={['toggle-input', 'toggle-label']}
    >
      <TitleTertiary color="#2D3745" fontWeight="500">
        {t('moleculeslist.add_molecules')}
      </TitleTertiary>
      <Wrapper>
        <Content>
          <SelectWithLabel>
            <Caption color={theme.colors.text.secondary} fontWeight={400}>
              {t('moleculeslist.select_method')}
            </Caption>
            <CustomSelect
              selectedValue={addType}
              onChange={handleSelect}
              width="100%"
              options={optionsForRender}
              id="add-molecule-select"
            />
          </SelectWithLabel>

          {addType.value === 'editor' && (
            <KetcherBlock>
              <CustomInput
                onChange={handleChangeSmiles}
                placeholder="SMILES"
                value={smiles}
                withClearButton={true}
                flex={1}
              />
              <CustomButton
                type="tertiary"
                onClick={() => setShowKetcher(true)}
              >
                <Icon iconType="edit" size="1rem" />
              </CustomButton>
            </KetcherBlock>
          )}

          {addType.value === 'smiles' && (
            <CustomInput
              withClearButton
              onChange={handleChangeSmiles}
              value={smiles}
              placeholder={t('moleculeslist.enter_smiles')}
            />
          )}

          {addType.value === 'synonyms' && (
            <InputHelper
              onAdd={(smiles) => {
                setSmiles(smiles)
              }}
              showOptions={showOptions}
              onFocus={handlePointerIn}
              onBlur={handlePointerOut}
            />
          )}
          {addType.value === 'file' && (
            <>
              {!file && (
                <>
                  <FileInput
                    accept=".sdf, .smi, .csv"
                    id="raised-button-file"
                    type="file"
                    onChange={handleLoad}
                  />
                  <label htmlFor="raised-button-file">
                    <CustomButton type="text" gap="0.25rem" width="fit-content">
                      {t('moleculeslist.select_file')}
                      <Icon iconType="upload" size="1rem" />
                    </CustomButton>
                  </label>
                </>
              )}

              {file && file.type.includes('csv') && (
                <>
                  <CustomRadioList
                    id="delimiter-radio-select"
                    config={delimiterOptionsConfig}
                    state={delimiterState}
                    handleSelect={(delimiter) => {
                      if (file) {
                        if (delimiter !== 'other' && ownDelimiter) {
                          setOwnDelimiter('')
                        }
                        parseListFromCsvFile({
                          csv_file: file,
                          delimiterToUse: delimiter,
                        })
                      }
                      setDelimiterState(delimiter)
                    }}
                  />
                  {delimiterState === 'other' && (
                    <CustomInput
                      placeholder={t('baskets.write_delimiter')}
                      label={t('baskets.write_delimiter')}
                      value={ownDelimiter}
                      onChange={(value) => {
                        if (value === '' || validateDelimiterSymbols(value)) {
                          setOwnDelimiter(value)
                        }
                      }}
                      withClearButton
                    />
                  )}
                  <SelectWithLabel>
                    <Caption
                      color={theme.colors.text.secondary}
                      fontWeight={400}
                    >
                      {t('moleculeslist.select_the_column_heading')}
                    </Caption>
                    <CustomSelect
                      selectedValue={{
                        value: fileTargetHeading,
                        label: fileTargetHeading,
                      }}
                      onChange={(option) => setFileTargetHeading(option.value)}
                      options={listFromFile.map((el) => ({
                        value: el,
                        label: el,
                      }))}
                      withTranslation={false}
                      withSelectListUp
                    />
                  </SelectWithLabel>
                </>
              )}
            </>
          )}
          {!!file && (
            <>
              <FileInfo>
                <Icon iconType={fileIcon} size="2.5rem" />
                <Subheadline
                  ref={nameRef}
                  flex={1}
                  data-tip
                  data-for="file-info-name"
                >
                  {file.name}
                </Subheadline>
                <Icon
                  iconType="deleted"
                  size="1rem"
                  onClick={() => {
                    setFile(null)
                    setOwnDelimiter('')
                    setDelimiterState(',')
                  }}
                />
              </FileInfo>
              {nameRef?.current?.scrollHeight >
                nameRef?.current?.clientHeight && (
                <ReactTooltip
                  id={'file-info-name'}
                  className="c-tooltip c-tooltip-base c-tooltip-filename"
                  classNameArrow="c-tooltip-arrow"
                  place={'bottom'}
                >
                  {file.name}
                </ReactTooltip>
              )}
            </>
          )}
        </Content>

        {!!smiles.trim() && (
          <MoleculeStructure
            structure={smiles}
            width={SMILES_SIZE.width}
            height={SMILES_SIZE.height}
            hideEmpty
            className={'molecule-add-smiles-view'}
            dataTest={'ketcher-block-modal'}
          />
        )}
      </Wrapper>

      <CustomButton
        width="100%"
        type="accent"
        disabled={isUploadButtonDisabled}
        onClick={!isUploadButtonDisabled && handleLoadtoSyn}
      >
        {t('moleculeslist.upload')}
      </CustomButton>
      {showKetcher && (
        <Ketcher
          withCloseButton
          handleData={(v) => setSmiles(v)}
          closeKetcher={() => setShowKetcher(false)}
          smiles={smiles || undefined}
        />
      )}
    </CustomDialog>
  )
}

const mapDispatchToProps = {
  addNotification,
}

export default connect(null, mapDispatchToProps)(MoleculeAddViewRestyled)
