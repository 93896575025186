import Params from 'components/MoleculeViewer/components/MoleculeFooter/Params'
import MoleculeLinks from 'components/MoleculeLinks'

import { DataContainer, ParamsWithNote } from './index.style'
import Alias from './Alias'
import NoteBtn from './NoteBtn'

const MoleculeFooter = ({
  num,
  note,
  text,
  alias,
  params,
  withColors,
  onOpenNote,
  isBestMatch,
  isShowMolName,
  referencesCount,
  onLinkActionClick,
  isSimilarResults,
}) => {
  return (
    <DataContainer isBestMatch={isBestMatch}>
      {/* для результатов поиска alias никогда не показываем, поскольку в этой карточке отображаем text */}
      {!isBestMatch && !isSimilarResults && alias && (
        <Alias {...{ num, alias }} />
      )}

      {(note || params) && (
        <ParamsWithNote>
          <Params {...{ num, params, withColors }} />
          <NoteBtn {...{ num, note, onOpenNote }} />
        </ParamsWithNote>
      )}
      {isShowMolName && !!text && <Alias {...{ num }} alias={text} />}
      <MoleculeLinks
        {...{ onLinkActionClick, referencesCount, isBestMatch }}
        isMoleculeViewer
      />
    </DataContainer>
  )
}

export default MoleculeFooter
