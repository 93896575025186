import { Caption, TitleTertiary } from 'components/common/text/index.style'
import styled, { css } from 'styled-components'
export const HEADER_FOOTER_HEIGHT = 113

export const Container = styled.div`
  align-self: flex-start;

  border-radius: 0.75rem;
  max-width: 100%;
  width: ${({ isBestMatch, isFullWidth, isDialog, containerWidth }) => {
    if (containerWidth) return `${containerWidth}px`
    if (isFullWidth || isDialog) return '100%'
    if (isBestMatch) return 'calc(100% - 0.25rem)'
  }};
  ${({ noClick }) =>
    noClick &&
    `
      pointer-events: none;
      cursor: arrow !important;
    `}

  @media print {
    width: 100%;
  }

  &:hover {
    box-shadow: ${({ noContainerHover, theme }) =>
      !noContainerHover ? theme.shadow.default : ''};
  }
`

export const ToolButton = styled.button`
  display: flex;
  background: transparent;
  outline: none;
  border: none;
  padding: 8px;
  border-radius: 8px;
  flex-direction: column;
  align-self: flex-end;
  row-gap: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    background: #ffffff;
  }

  & > svg {
    color: #ced6e3;

    ${({ disabled }) =>
      !disabled &&
      css`
        &:hover {
          color: #42c1bf;
          :nth-child(2) {
            color: ${({ withDisabledIcon }) =>
              withDisabledIcon ? '#CED6E3' : '#42c1bf'};
          }
        }
      `}
  }
`

export const HambButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }

  &:hover {
    & > svg {
      color: #42c1bf;
    }
  }
`
export const BigHumbButton = styled(HambButton)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SmallCardHambButton = styled(HambButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  &:hover {
    & > svg {
      color: ${({ disabled, theme }) =>
        disabled && theme.colors.icons.tertiary};
    }
  }
`

export const HambButtonWhite = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  max-height: 40px;
  z-index: 5;
  opacity: 0;
  box-shadow: ${({ theme }) => theme.shadow.default};
  background: #ffffff;

  & > svg {
    width: 20px;
    height: 20px;
    color: #9aa5b1;
  }

  &:hover {
    & > svg {
      fill: #42c1bf;
    }
  }
`

export const OneMolecula = styled.div`
  border-radius: 0.75rem;
  background-color: ${(props) =>
    props.isRetrosynthesisMolecule
      ? 'F5F7F9'
      : props.isDialog
      ? props.theme.colors.backgrounds.secondary
      : 'white'};
  display: flex;
  flex-direction: ${({ isBestMatch }) => !isBestMatch && 'column'};
  position: relative;
  cursor: ${({ cursor }) => cursor};
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.3s;
  width: ${({ isBestMatch }) => isBestMatch && '100%'};
  gap: ${({ isBestMatch }) => isBestMatch && '1rem'};
  padding: ${({ isBestMatch }) => isBestMatch && '1rem'};

  &:hover {
    ${(noHover) =>
      !noHover &&
      css`
        box-shadow: ${({ theme }) => theme.shadow.default};
      `}
  }

  ${({ type }) =>
    type === 'add-new' &&
    `
    border: 1px solid transparent;
    &:hover {
      border: 1px solid transparent;
      box-shadow: none;
    }
  `};

  ${({ type }) =>
    type === 'solid-border' &&
    css`
      border: 1px solid #f5f7f7;
      &:hover {
        border: 1px solid #f5f7f7;
        box-shadow: none;
      }
    `};

  ${({ type }) =>
    type === 'gray-border' &&
    css`
      border: 1px solid #e6ebf1;
      &:hover {
        border: 1px solid #e6ebf1;
        box-shadow: none;
      }
    `};

  ${({ type }) =>
    type === 'no-border' &&
    `
    border: 1px solid transparent;
    &:hover {
      border: 1px solid transparent;
      box-shadow: none;
    }
  `};

  ${({ type }) =>
    type === 'no-border-carousel-dialog' &&
    css`
      border: 1px solid transparent;
      &:hover {
        border: 1px solid transparent;
      }
      box-shadow: ${({ theme }) => theme.shadow.default};
    `};

  ${({ type }) =>
    type === 'carousel-dialog-selected' &&
    css`
      border: 1px solid #42c1bf;
      &:hover {
        border: 1px solid #42c1bf;
      }
      box-shadow: ${({ theme }) => theme.shadow.default};
    `};

  ${({ type }) =>
    type === 'selected' &&
    `
    border: 1px solid #42C1BF;
    &:hover {
      border: 1px solid #42C1BF;
      box-shadow: none;
    }
  `};

  border: ${({ isBestMatch }) => isBestMatch && 'none'};

  &:hover {
    & ${HambButtonWhite} {
      opacity: 100%;
    }
  }
`

export const SmilesViewContainer = styled.div`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  @media print {
    width: 100%;
  }
`

export const EmptySmilesView = styled.div`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height, maxHeightClicknAdd }) =>
    height && `${height + (maxHeightClicknAdd ? HEADER_FOOTER_HEIGHT : 0)}px`};
`

export const AddText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
`

export const IconButton = styled.button`
  background: #ffffff;
  align-self: center;
  outline: none;
  border: none;
  padding: 12px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.default};
  max-height: 40px;
  column-gap: 8px;
  display: flex;

  & > svg {
    fill: #42c1bf;
  }

  opacity: ${({ $onhover }) => ($onhover ? '0%' : '100%')};
  cursor: pointer;
  & > svg {
    fill: #9aa5b1;
    width: 20px;
    height: 20px;
  }

  ${OneMolecula}:hover & {
    opacity: 100%;
    & > svg {
      &:hover {
        fill: #42c1bf;
      }
    }
  }
`

export const AddNew = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  justify-content: center;
  text-align: center;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  & ${AddText} {
    color: ${({ isAddDialogOpen }) =>
      isAddDialogOpen ? '#42C1BF' : '#2d3745'};
  }

  & ${IconButton} {
    box-shadow: none;
    padding: 10px;
    border-radius: 12px;
    background-color: #f5f7f9;
    cursor: ${({ disabled }) => disabled && 'not-allowed'};

    & > svg {
      width: 16px;
      height: 16px;
      color: ${({ isAddDialogOpen }) =>
        isAddDialogOpen ? '#42C1BF' : '#2d3745'};
    }
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        & ${AddText} {
          color: ${({ theme }) => theme.colors.text.accentPrimary};
        }
        & ${IconButton} > svg {
          color: ${({ theme }) => theme.colors.text.accentPrimary};
        }

        & ${Caption} {
          color: ${({ theme }) => theme.colors.text.accentPrimary};
        }
      }
    `}
`

export const ToolsBtn = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  cursor: pointer;
  z-index: 1;

  & ${ToolButton} {
    & > svg {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out;
    }

    & > svg:first-child {
      opacity: 100%;
      visibility: visible;
    }
  }

  &:hover {
    & ${ToolButton} {
      box-shadow: ${({ theme, disabled }) => !disabled && theme.shadow.default};
      transition: height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      & > svg {
        opacity: 100%;
        visibility: visible;
        transition: 0.3s ease-in-out;
      }
    }
  }
`

export const ToolsBtnNew = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  cursor: pointer;
  z-index: 1;

  & ${ToolButton} {
    & > svg {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out;
    }

    & > svg:first-child {
      opacity: 100%;
      visibility: visible;
      color: ${({ isMolMenuOpen }) => (isMolMenuOpen ? '#42C1BF' : '#CED6E3')};
      &:hover {
        color: #42c1bf;
      }
    }

    &:hover {
      & ${ToolButton} {
        box-shadow: ${({ theme }) => theme.shadow.default};
        transition: height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

        & > svg {
          opacity: 100%;
          visibility: visible;
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  ${({ isMolMenuOpen, isHovered }) =>
    (isMolMenuOpen || isHovered) &&
    `& ${ToolButton} {
			background: white;
      box-shadow: ${({ theme }) => theme.shadow.default};
      transition: height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      & > svg {
        opacity: 100%;
        visibility: visible;
        transition: 0.3s ease-in-out;
      }
    } `};
`

export const Tags = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  margin: ${({ withMargin }) => withMargin && '0 0.75rem 0.75rem'};
  div {
    background: ${({ withBackground }) => withBackground && '#F5F7F7'};
  }
`

export const SmilesWithIconContainer = styled.div`
  gap: 4px;
  width: 100%;
`

export const Smiles = styled.div`
  font-size: 12px;
  overflow: hidden;
  font-weight: 500;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
`
export const SmallBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  transition: all 0.3s ease;

  ${({ withBlur }) =>
    withBlur &&
    `background: rgba(97, 110, 124, 0.08);
  backdrop-filter: blur(6px); `};

  align-items: center;
  justify-content: center;
  overflow: hidden;
`
export const Block = styled.div`
  top: 0;
  left: 0;
  padding: 8px;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ClicknAddFooter = styled.div`
  min-height: 52px;
`

export const MoleculeName = styled(TitleTertiary)`
  padding: ${({ isBestMatch }) => !isBestMatch && '0 1.25rem 0.5rem'};
  min-height: ${({ isBestMatch }) => !isBestMatch && '1.75rem'};
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ isBestMatch }) => isBestMatch && '0.75rem'};
  align-self: ${({ isBestMatch }) => isBestMatch && 'center'};
`

export const BestSmilesViewContainer = styled.div`
  width: 140;
  height: 120;
`
