import {
  SPECTRA_MS_SUCCESS,
  SPECTRA_MS_INIT,
  SPECTRA_MS_FAIL,
  SPECTRA_RESET,
  SET_SPECTRA_TYPE,
  SET_ION_MODE,
  SET_ADDUCT_TYPE,
  SET_ROUNDING_MZ,
  SET_ROUNDING_RI,
  SET_NMR_METHOD,
  SET_CALCULATED_MS_TYPE,
  SET_INFRARED_SHOOTING_METHOD,
  SPECTRA_NMR_INIT,
  SPECTRA_NMR_SUCCESS,
  SPECTRA_NMR_FAIL,
  SET_NMR_CHART_ACTIVE_ATOM,
  SET_NMR_ADDINTION_ATOM,
  SPECTRA_INFRARED_INIT,
  SPECTRA_INFRARED_SUCCESS,
  SPECTRA_INFRARED_FAIL,
  SPECTRA_CLEAR_DATA,
} from 'store/constants/spectra'

const initialState = {
  nmr: {
    method: {},
    data: {},
    loading: false,
    error: false,
    error_text: 'error',
    chartActiveAtoms: [],
    additionalAtom: null,
  },
  infrared: {
    data: [],
    loading: false,
    error: false,
    error_text: 'error',
    shootingMethod: { value: 'gas', label: 'gas' },
  },
  data: {},
  loading: false,
  error: false,
  error_text: 'error',
  spectraType: 'nmr',
  ion_mode: { value: 'positive', label: 'Positive' },
  adduct_type: { value: '[M+H]+', label: '[M+H]+' },
  roundingRI: { value: 2, label: '2' },
  roundingMZ: { value: 2, label: '2' },
  calculatedMSType: 'low_energy',
}

const spectraReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SPECTRA_MS_INIT:
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
      }
    case SPECTRA_MS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: false,
      }
    case SPECTRA_MS_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        error_text: payload.error.response.data.detail,
      }
    case SPECTRA_NMR_INIT:
      return {
        ...state,
        nmr: { ...state.nmr, data: {}, loading: true, error: false },
      }
    case SPECTRA_NMR_SUCCESS:
      return {
        ...state,
        nmr: { ...state.nmr, data: payload.data, loading: false, error: false },
      }
    case SPECTRA_NMR_FAIL:
      return {
        ...state,
        nmr: {
          ...state.nmr,
          data: {},
          loading: false,
          error: true,
          error_text: payload.error.response.data.detail,
        },
      }

    case SPECTRA_INFRARED_INIT:
      return {
        ...state,
        infrared: { ...state.infrared, data: [], loading: true, error: false },
      }
    case SPECTRA_INFRARED_SUCCESS:
      return {
        ...state,
        infrared: {
          ...state.infrared,
          data: payload.data,
          loading: false,
          error: false,
        },
      }
    case SPECTRA_INFRARED_FAIL:
      return {
        ...state,
        infrared: {
          ...state.infrared,
          data: [],
          loading: false,
          error: true,
          error_text: payload.error.response.data.detail,
        },
      }

    case SPECTRA_RESET:
      return {
        ...state,
        nmr: {
          method: {},
          data: {},
          loading: false,
          error: false,
          error_text: 'error',
          chartActiveAtoms: [],
          additionalAtom: { shift: null, intensity: null },
        },
        infrared: {
          data: [],
          loading: false,
          error: false,
          error_text: 'error',
          shootingMethod: { value: 'gas', label: 'gas' },
        },
        data: {},
        ion_mode: { value: 'positive', label: 'Positive' },
        adduct_type: { value: '[M+H]+', label: '[M+H]+' },
        roundingRI: { value: 2, label: '2' },
        roundingMZ: { value: 2, label: '2' },
        calculatedMSType: 'low_energy',
        infraredShootingMethod: { value: 'gas', label: 'gas' },
        loading: false,
        error: false,
      }

    case SPECTRA_CLEAR_DATA:
      return {
        ...state,
        nmr: {
          method: state.spectraType === 'nmr' ? { ...state.nmr.method } : {},
          data: {},
          loading: false,
          error: false,
          error_text: 'error',
          chartActiveAtoms: [],
          additionalAtom: { shift: null, intensity: null },
        },
        infrared: {
          data: [],
          loading: false,
          error: false,
          error_text: 'error',
          shootingMethod:
            state.spectraType === 'infraredSpectrometry'
              ? { ...state.infrared.shootingMethod }
              : { value: 'gas', label: 'gas' },
        },
        data: {},
        ...(state.spectraType === 'ms'
          ? {
              ion_mode: { ...state.ion_mode },
              adduct_type: { ...state.adduct_type },
              roundingRI: { ...state.roundingRI },
              roundingMZ: { ...state.roundingMZ },
            }
          : {
              ion_mode: { value: 'positive', label: 'Positive' },
              adduct_type: { value: '[M+H]+', label: '[M+H]+' },
              roundingRI: { value: 2, label: '2' },
              roundingMZ: { value: 2, label: '2' },
            }),
        calculatedMSType: 'low_energy',
        loading: false,
        error: false,
      }
    case SET_SPECTRA_TYPE:
      return { ...state, spectraType: payload.spectraType }
    case SET_ION_MODE:
      return { ...state, ion_mode: payload.ionMode }
    case SET_ADDUCT_TYPE:
      return { ...state, adduct_type: payload.adduct_type }
    case SET_ROUNDING_MZ:
      return { ...state, roundingMZ: payload.roundingMZ }
    case SET_ROUNDING_RI:
      return { ...state, roundingRI: payload.roundingRI }
    case SET_NMR_METHOD:
      return { ...state, nmr: { ...state.nmr, method: payload.nmrMethod } }
    case SET_CALCULATED_MS_TYPE:
      return {
        ...state,
        calculatedMSType: payload.calculatedMSType,
      }
    case SET_INFRARED_SHOOTING_METHOD:
      return {
        ...state,
        infrared: {
          ...state.infrared,
          shootingMethod: payload.infraredShootingMethod,
        },
      }
    case SET_NMR_CHART_ACTIVE_ATOM:
      return {
        ...state,
        nmr: { ...state.nmr, chartActiveAtoms: payload.activeAtoms },
      }
    case SET_NMR_ADDINTION_ATOM:
      return {
        ...state,
        nmr: {
          ...state.nmr,
          additionalAtom: payload.additionalAtom,
        },
      }
    default:
      return state
  }
}

export default spectraReducer
