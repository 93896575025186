import { memo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { confirm } from 'store/actions/confirm'
import { deleteMolecule } from 'store/actions/crud'
import MoleculeViewer from 'components/MoleculeViewer'
import LoaderRestyling from 'components/LoaderRestyling'

import { calculateMolWidthInPercents } from '../utils/utils'
import { MOLECULES_LIST_PATH } from '../config/config'
import {
  MolBlock,
  MolBlockContainer,
  MolData,
  MolList,
  MolListAbs,
  SimilarData,
  SimilarTitle,
} from './index.style'
import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import { DEFAULT_TLIGHT } from 'components/MoleculeViewer/components/MoleculeFooter/Params/config/config'

const Molecules = memo(
  ({
    data,
    path,
    onEdit,
    propsId,
    confirm,
    viewSize,
    onSelect,
    withData,
    searchV2,
    isPublic,
    selected,
    withColors,
    onShowProps,
    unselectAll,
    bingoSearch,
    showPagination,
    currentBasketId,
  }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isSearchPage = !path.split('/').includes(MOLECULES_LIST_PATH)
    const isDatasetMoleculesList = path?.includes('molecules/')
    const { molWidth, molMaxWidth } = calculateMolWidthInPercents(viewSize)

    const isDefaultDataRender =
      searchV2.status === 'hold' && !isSearchPage && data?.length > 0

    const isSearchV2DataRender =
      searchV2.status === 'done' &&
      Array.isArray(searchV2.result.similar_results)

    const isSearchV2HistoryDataRender =
      searchV2.status === 'done' && Array.isArray(searchV2.result)

    const isBingoSearchDataRender =
      bingoSearch.status === 'done' && searchV2.status !== 'loading'

    const dataForRender = isDefaultDataRender
      ? data
      : isSearchV2DataRender
      ? searchV2.result.similar_results
      : isSearchV2HistoryDataRender
      ? searchV2.result
      : bingoSearch.cache.pageItems

    const handleDelete = (id) => {
      deleteMolecule(id, currentBasketId || 1)
      unselectAll()
    }

    const handleOpenConfirmationDialog = (id) =>
      confirm(
        `${t('moleculeslist.areYouSureDelete')} ${t(
          'moleculeslist.thisItem'
        )}?`,
        () => handleDelete(id)
      )

    const isLoading =
      searchV2.status === 'loading' || bingoSearch.status === 'loading'

    return (
      <MolBlockContainer id={'molecules'} isLoading={isLoading}>
        <MolBlock>
          <MolList onClick={unselectAll}>
            {isLoading ? (
              <LoaderRestyling />
            ) : (
              <MolData>
                {searchV2.result.exact_match && (
                  <MoleculeViewer
                    num={searchV2.result.exact_match.id}
                    width={132}
                    height={108}
                    handleClick={onSelect}
                    withColors={withColors}
                    withData={withData}
                    smiles={searchV2.result.exact_match.smiles}
                    params={JSON.stringify(
                      searchV2.result.exact_match?.tlight || DEFAULT_TLIGHT
                    )}
                    type={
                      !!selected.find(
                        (s) => s.id === searchV2.result.exact_match.id
                      ) && 'selected'
                    }
                    onEdit={onEdit}
                    onDelete={handleOpenConfirmationDialog}
                    onShowProps={onShowProps}
                    noDelete={
                      isDefaultDataRender
                        ? isPublic
                        : searchV2.result.exact_match.public
                    }
                    noEdit={true}
                    showId
                    isOnlyThreeDotsShow={false}
                    maxHeightClicknAdd={true}
                    noContextMenu={!!propsId}
                    currentBasketId={currentBasketId}
                    referencesCount={
                      searchV2.result.exact_match.references_in_documents
                    }
                    isBestMatch={true}
                    text={searchV2.result.exact_match?.text}
                    isShowMoleculeEdit={isDatasetMoleculesList && !isPublic}
                    alias={searchV2.result.exact_match?.alias}
                    note={searchV2.result.exact_match?.note}
                    isShowMolName={true}
                  />
                )}
                <SimilarData>
                  {isSearchV2DataRender &&
                    searchV2.result.similar_results.length > 0 && (
                      <SimilarTitle>
                        <Icon iconType={'predict'} size={'1rem'} />
                        <Label fontWeight={theme.fontWeight.semibold}>
                          {t('moleculeslist.similar_results')}
                        </Label>
                      </SimilarTitle>
                    )}
                  <MolListAbs
                    molWidth={molWidth}
                    id="molabs"
                    showPagination={showPagination}
                    molMaxWidth={molMaxWidth}
                    isDatasetMoleculesList={isDatasetMoleculesList}
                  >
                    {(isDefaultDataRender ||
                      isSearchV2DataRender ||
                      isBingoSearchDataRender ||
                      isSearchV2HistoryDataRender) &&
                      dataForRender.map((el, ind) => {
                        return (
                          <MoleculeViewer
                            key={ind}
                            num={el.id}
                            size={viewSize}
                            handleClick={onSelect}
                            withColors={withColors}
                            withData={withData}
                            smiles={el.smiles}
                            params={JSON.stringify(
                              el?.tlight || DEFAULT_TLIGHT
                            )}
                            type={
                              !!selected.find((s) => s.id === el.id) &&
                              'selected'
                            }
                            onEdit={onEdit}
                            onDelete={handleOpenConfirmationDialog}
                            onShowProps={onShowProps}
                            noDelete={
                              isDefaultDataRender ? isPublic : el.public
                            }
                            noEdit={true}
                            showId
                            isOnlyThreeDotsShow={false}
                            noContextMenu={!!propsId}
                            currentBasketId={currentBasketId}
                            referencesCount={el?.references_in_documents}
                            text={el?.text}
                            isShowMolName={
                              isSearchV2DataRender ||
                              isSearchV2HistoryDataRender
                            }
                            isShowMoleculeEdit={
                              isDatasetMoleculesList && !isPublic
                            }
                            alias={el?.alias}
                            note={el?.note}
                            isSimilarResults={isSearchV2DataRender}
                          />
                        )
                      })}
                  </MolListAbs>
                </SimilarData>
              </MolData>
            )}
          </MolList>
        </MolBlock>
      </MolBlockContainer>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    data: state.crud.molecules,
    isPublic: state.crud.public,
    searchV2: state.crud.searchV2,
    viewSize: state.settings.viewSize,
    bingoSearch: state.crud.bingoSearch,
    path: state.router.location.pathname,
  }
}

const mapDispatchToProps = {
  confirm,
  deleteMolecule,
}

Molecules.displayName = 'Molecules'
export default connect(mapStateToProps, mapDispatchToProps)(Molecules)
