import styled from 'styled-components'
import CustomButton from '../customButton'
import { scrollbarStyles } from '../scrollbar/scrollbar.style'

export const Backdrop = styled.div`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background: ${({ withBackdrop }) =>
    withBackdrop && 'rgba(97, 110, 124, 0.08)'};
  backdrop-filter: ${({ withBackdrop }) => withBackdrop && 'blur(6px)'};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media print {
    position: static;
    width: 100%;
    height: auto;
    backdrop-filter: none;
    display: block;
  }
`

export const Dialog = styled.div`
  position: ${({ pos }) => pos};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  display: flex;
  min-width: ${({ minWidth }) => minWidth && minWidth};
  width: ${({ width }) => (width ? width : 'auto')};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'none')};
  height: ${({ height, maxHeight }) => height || maxHeight || 'none'};
  padding: ${({ padding }) => (padding ? padding : '24px')};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '24px')};
  border-radius: 20px;
  background: #fff;
  overflow-y: ${({ overflowY }) => overflowY ?? 'default'};
  box-shadow: ${({ theme }) => theme.shadow.default};

  ${scrollbarStyles}

  @media print {
    position: static;
    width: 100%;
    padding: 0;
    box-shadow: none;
    max-height: none;
    padding: 0;
    display: block;
  }
`

export const CustomDialogHeader = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.25rem;
  word-break: break-word;
`

export const StyledButton = styled(CustomButton)`
  position: absolute;
  top: 0rem;
  width: ${({ size }) => (size === 'big' ? '3.5rem' : '3.25rem')};
  height: ${({ size }) => (size === 'big' ? '3.5rem' : '3.25rem')};
  right: 0rem;
  z-index: 10;
`
