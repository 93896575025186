import { useTranslation } from 'react-i18next'

import {
  FiltersContainer,
  FiltersRow,
  InputWithLabel,
  LeftBlock,
  RightBlock,
} from './index.style'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'

import { memo, useMemo, useState } from 'react'
import { Caption } from 'components/common/text/index.style'
import CustomSelect from 'components/CustomSelect'
import {
  themeOptions,
  modules,
  FEEDBACK_SORT_TYPE_CONFIG,
} from 'components/Feedback/FeedbackForm/config'
import {
  InputWrapper,
  ButtonsBlock,
} from 'pages/AdminPanelPage/editCompanyDialog/index.style'
import CustomInput from 'components/common/customInput'
import { formatUtcDigitDateByFormat } from 'utils/dateTime/formatUtcDate'
import RangeCalendar from 'pages/AdminPanelPage/rangeCalendar'
import {
  clearFeedbackFilter,
  getFeedback,
  setFeedbackFilter,
  setFeedbackPaginationConfig,
} from 'store/actions/feedback'
import { useTheme } from 'styled-components'
import SortPanel from 'components/SortPanel'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import MultiSelectWithGroups from 'components/DatasetTable/CustomFilter/MultiSelectWithGroups'

const FeedbackFilters = memo(({ scrollTop }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const selected_theme = useSelector((state) => state.feedback.filter.theme)
  const selected_modules = useSelector((state) => state.feedback.filter.modules)
  const date = useSelector((state) => state.feedback.filter.date)
  const email = useSelector((state) => state.feedback.filter.email)
  const sortType = useSelector((state) => state.feedback.filter.sort.type)
  const sortDirection = useSelector(
    (state) => state.feedback.filter.sort.direction
  )
  const offset = useSelector((state) => state.feedback.offset)

  const pagination = useSelector((state) => state.feedback.pagination)
  const { perPage: limit } = pagination || {}
  const [calendarOpen, setCalendarOpen] = useState(false)
  const handleToggleCalendar = () => {
    setCalendarOpen((prev) => !prev)
  }
  const handleFilter = ({ key, value }) => {
    dispatch(setFeedbackFilter({ key, value }))
  }
  const themeOptionsExtended = [
    { id: 'all', label: 'feedback.themes.all', value: 'all' },
    ...themeOptions,
  ]

  const handleChangeSorting = (type, value) => {
    if (type === 'direction') {
      dispatch(
        setFeedbackFilter({
          key: 'sort',
          value: { type: sortType, direction: value },
        })
      )
    }
    if (type === 'type') {
      dispatch(
        setFeedbackFilter({
          key: 'sort',
          value: { type: value, direction: sortDirection },
        })
      )
    }
  }
  const setCalendarValues = (values) => {
    dispatch(
      setFeedbackFilter({
        key: 'date',
        value: values,
      })
    )
  }

  const getAllowedModules = (theme) => {
    if (theme === 1 || theme === 5) return []
    if (theme === 4)
      return modules.filter((module) => {
        return [1, 2, 7].includes(module.id)
      })
    return modules
  }
  const allowedModules = useMemo(() => {
    return getAllowedModules(selected_theme)
  }, [selected_theme])

  return (
    <FiltersContainer>
      <LeftBlock>
        <FiltersRow>
          <InputWithLabel>
            <Caption color={theme.colors.text.secondary}>
              {t('feedback.theme')}
            </Caption>
            <CustomSelect
              emptyLabel={t('common.not_selected')}
              selectedValue={{
                value: selected_theme,
                label: themeOptionsExtended.find(
                  (el) => el.value === selected_theme
                )?.label,
              }}
              onChange={(option) => {
                handleFilter({ key: 'theme', value: option?.value })
                handleFilter({ key: 'modules', value: [] })
              }}
              options={themeOptionsExtended}
              withTranslation={true}
              width="100%"
            />
          </InputWithLabel>
          <InputWithLabel>
            <Caption color={theme.colors.text.secondary}>
              {t('feedback.module')}
            </Caption>
            <MultiSelectWithGroups
              withTranslation={true}
              allSelectedLabel={t('feedback.modules.all')}
              emptyLabel={t('common.not_selected')}
              options={allowedModules}
              width="100%"
              selectedValue={selected_modules}
              onChange={(option) =>
                handleFilter({ key: 'modules', value: option })
              }
              disabled={selected_theme === 1 || selected_theme === 5}
            />
          </InputWithLabel>
        </FiltersRow>
        <FiltersRow className="email-sort-row">
          <CustomInput
            innerClassName="feedback-email-input"
            value={email}
            placeholder="Введите email пользователя"
            onChange={(value) => handleFilter({ key: 'email', value })}
          />
          <SortPanel
            {...{ sortType }}
            onChangeSorting={handleChangeSorting}
            sortOptions={FEEDBACK_SORT_TYPE_CONFIG}
            switcherID="feedbackSortDirection"
            selectClassName="feedback-sort-type-select"
          />
        </FiltersRow>
      </LeftBlock>
      <RightBlock>
        <FiltersRow>
          <ButtonsBlock>
            <InputWrapper onClick={handleToggleCalendar}>
              <CustomInput
                label={t('feedback.feedback_date')}
                pseudoBeforeText={t('filter.from_rus')}
                placeholder={t('filter.from_rus')}
                gap="0.375rem"
                value={
                  date?.[0]
                    ? formatUtcDigitDateByFormat(
                        new Date(date[0]),
                        'dd.MM.yyyy'
                      )
                    : ''
                }
                readOnly
              />
            </InputWrapper>
            <InputWrapper onClick={handleToggleCalendar}>
              <CustomInput
                label={' '}
                pseudoBeforeText={t('filter.to_rus')}
                gap="0.375rem"
                width="100%"
                value={
                  date?.[1]
                    ? formatUtcDigitDateByFormat(
                        new Date(date[1]),
                        'dd.MM.yyyy'
                      )
                    : ''
                }
                placeholder={t('filter.to_rus')}
                readOnly
              />
            </InputWrapper>
          </ButtonsBlock>
        </FiltersRow>
        <FiltersRow>
          <CustomButton
            onClick={() => {
              dispatch(clearFeedbackFilter())
              dispatch(
                setFeedbackPaginationConfig({
                  pagesAmount: 0,
                  total: 0,
                  perPage: 24,
                  activePage: 1,
                })
              )
              scrollTop()
              dispatch(getFeedback({ limit, offset }))
            }}
            type="secondary"
            minWidth="40%"
            gap="0.25rem"
          >
            {t('spectra.buttons.reset')}
            <Icon iconType="deleted" size="1rem" style={{ minWidth: '1rem' }} />
          </CustomButton>
          <CustomButton
            onClick={() => {
              dispatch(getFeedback({ limit, offset }))
            }}
            type="accent"
            width="100%"
            gap="0.25rem"
          >
            {t('admin.filter.set_filter')}
            <Icon
              iconType="arrowRight"
              size="1rem"
              style={{ minWidth: '1rem' }}
            />
          </CustomButton>
        </FiltersRow>
      </RightBlock>

      {calendarOpen && (
        <RangeCalendar
          values={date}
          setValues={setCalendarValues}
          onClose={() => {
            setCalendarOpen(false)
          }}
        />
      )}
    </FiltersContainer>
  )
})
FeedbackFilters.displayName = 'FeedbackFilters'
export default FeedbackFilters
