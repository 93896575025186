import { css, default as styled } from 'styled-components'

export const TitlePrimary = styled.h1`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[24]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.text[28]};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const TitleSecondary = styled.h2`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[20]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.semibold};
  line-height: ${(props) => props.theme.text[24]};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const TitleTertiary = styled.h3`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[16]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.text[20]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
`

export const Headline = styled.div`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[14]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.light};
  line-height: ${(props) => props.theme.text[18]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
`

export const TextButtonLarge = styled.p`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[14]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.text[20]};
  margin: 0;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};
`

export const TextButtonMedium = styled.p`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.accentPrimary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[12]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.text[16]};
  margin: 0;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};
`

export const TextButtonRegular = styled(TextButtonMedium)

export const Label = styled.div`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[12]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.text[16]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
  ${({ name }) =>
    (name === 'smiles' || name === 'ellipsis') &&
    `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: block; width: 100%;`};

  @media print {
    ${({ name }) =>
      name === 'smiles' &&
      `text-overflow: clip; overflow: unset; white-space: wrap; display: block; width: 100%;`};
  }
`
// need to deprecate TextBody after styling
export const TextBody = styled.div`
  color: ${({ color }) => (color ? color : '#2d3745')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.875rem')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '1.125rem')};
`

export const TextLarge = styled.p`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[14]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.extraLight};
  line-height: ${(props) => props.theme.text[18]};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`

export const TextMedium = styled.p`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.text.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.text[12]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.extraLight};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ? lineHeight : theme.text[16]};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  margin: 0;
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
`
// need to depreacte Subheadline after styling
export const Subheadline = styled.div`
  color: ${({ color }) => (color ? color : '#2D3745')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.75rem')};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '1rem')};
  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}

  ${({ name }) =>
    name === 'smiles' &&
    `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: block; width: 100%;`};

  @media print {
    ${({ name }) =>
      name === 'smiles' &&
      `text-overflow: clip; overflow: unset; white-space: wrap; display: block; width: 100%;`};
  }
`

export const Caption = styled.div`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors?.text?.primary};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props?.theme?.text?.[11]};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.light};
  line-height: ${(props) =>
    props?.lineHeight ? props.lineHeight : props?.theme?.text?.[12]};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'visible')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'normal')};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  ${({ extraStyles }) =>
    extraStyles &&
    css`
      ${extraStyles}
    `}
`
export const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors?.text?.primary};
`
