export const getArticleLink = (name) => {
  switch (name) {
    case 'scscore_syba':
      return 'https://jcheminf.biomedcentral.com/articles/10.1186/s13321-020-00439-2'
    case 'scscore_comp':
      return 'https://pubs.acs.org/doi/abs/10.1021/acs.jcim.7b00622'
    case 'Acute Aquatic Toxicity 57455-2017':
      return 'https://www.rst.gov.ru/portal/gost/home/standarts/catalognational?portal:componentId=3503536e-2ac1-4753-8ed1-09a92fee02de&portal:isSecure=false&portal:portletMode=view&navigationalstate=JBPNS_rO0ABXc5AAZhY3Rpb24AAAABABBjb25jcmV0ZURvY3VtZW50AAZkb2NfaWQAAAABAAQ1NjYzAAdfX0VPRl9f'
    case 'Acute Toxicity Swallowed 56957-2016':
      return 'https://www.rst.gov.ru/portal/gost/home/standarts/catalognational?portal:componentId=3503536e-2ac1-4753-8ed1-09a92fee02de&portal:isSecure=false&portal:portletMode=view&navigationalstate=JBPNS_rO0ABXc5AAZhY3Rpb24AAAABABBjb25jcmV0ZURvY3VtZW50AAZkb2NfaWQAAAABAAQ2MTgzAAdfX0VPRl9f'
    case 'Selective Target-Organ Or System Toxicity Single Exposure 58479-2019':
      return 'https://www.rst.gov.ru/portal/gost/home/standarts/catalognational?portal:componentId=3503536e-2ac1-4753-8ed1-09a92fee02de&portal:isSecure=false&portal:portletMode=view&navigationalstate=JBPNS_rO0ABXc6AAZhY3Rpb24AAAABABBjb25jcmV0ZURvY3VtZW50AAZkb2NfaWQAAAABAAU0MzIwMgAHX19FT0ZfXw**'
    default:
      return ''
  }
}
