import { InView } from 'react-intersection-observer'

import { Category } from '../category'

export const ObservableCategory = ({
  category_id,
  category_name,
  inner,
  data,
  sources,
  isFullMode,
  changeCategoryVisibility,
  extended,
  setExtended,
  applicabilityData,
  isApplicabilityShow,
}) =>
  isFullMode ? (
    <InView
      as="div"
      onChange={(inView, _) => changeCategoryVisibility(category_id, inView)}
      threshold={0.2}
    >
      <Category
        category_id={category_id}
        category_name={category_name}
        inner={inner}
        isFullMode={isFullMode}
        data={data}
        sources={sources}
        extended={extended}
        setExtended={setExtended}
        applicabilityData={applicabilityData}
        isApplicabilityShow={isApplicabilityShow}
      />
    </InView>
  ) : (
    <Category
      category_id={category_id}
      category_name={category_name}
      inner={inner}
      isFullMode={isFullMode}
      data={data}
      sources={sources}
      extended={extended}
      setExtended={setExtended}
      applicabilityData={applicabilityData}
      isApplicabilityShow={isApplicabilityShow}
    />
  )
