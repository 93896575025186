import { useTranslation } from 'react-i18next'

import CustomButton from 'components/common/customButton'
import { TEST_PERIOD } from 'components/RegistrationPageNew/config'

import { Container } from './index.style'
import InfoWithProgress from '../InfoWithProgress'

const RegistrationInfo = ({ onGoToNextStep }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <InfoWithProgress
        title={t('registration.registration')}
        description={t('registration.we_offer', {
          count: TEST_PERIOD,
        })}
        additionalDescription={t('registration.if_you_have_key')}
        showProgress={false}
      />

      <CustomButton
        type="accent"
        onClick={onGoToNextStep}
        width="100%"
        data-test="go-registration-btn"
      >
        {t('registration.start_registration')}
      </CustomButton>
    </Container>
  )
}

export default RegistrationInfo
