import styled, { css } from 'styled-components'

export const MolBlockContainer = styled.div`
  display: flex;
  width: 100%;
  ${({ isLoading }) =>
    isLoading &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    `}
`

export const MolBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  row-gap: 1.75rem;
  transition: all 0.3s;
`
export const MolList = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  & .loader {
    margin-bottom: 7rem;
    align-self: center;
  }

  @media print {
    display: none;
  }
`

export const MolListAbs = styled.div`
  display: grid;
  column-gap: 8px;
  padding: 0.25rem;
  row-gap: 12px;
  grid-template-columns: ${({ molWidth, molMaxWidth }) =>
    `repeat(
    auto-fill,
    minmax(min(max(${molWidth}%, 15rem), ${molMaxWidth}rem), 1fr)
  )`};

  justify-content: ${({ isDatasetMoleculesList }) =>
    isDatasetMoleculesList ? 'space-around' : 'center'};
  align-content: flex-start;
  padding-top: 1px;
  padding-bottom: ${({ showPagination }) => (showPagination ? '80px' : '12px')};

  @media (max-width: 768px) {
    padding: 0 6px;
    gap: 8px;
    grid-template-columns: ${({ molWidth, molMaxWidth }) =>
      `repeat(
      auto-fill,
      minmax(min(max(${molWidth}%, 15rem), ${molMaxWidth}rem), 1fr)
    )`};
    justify-items: center;
  }
`

export const MolData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  top: 0;
  left: 0;
  right: 0;
  position: absolute;
`

export const SimilarData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const SimilarTitle = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`
