import {
  Caption,
  Headline,
  Label,
  Subheadline,
} from 'components/common/text/index.style'
import { Fragment, useCallback, useRef, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  CardWrapper,
  CardHeader,
  TypeRow,
  TypeNumber,
  Option,
  CardAbstract,
  CardReaction,
  CardMethodsWrapper,
  CardMethod,
  MethodYield,
  SourcesWrapper,
  Container,
  ReactBlock,
  CardContent,
  ShowMoreBlock,
  SvgWrapper,
  Source,
  ElementsWrapper,
  SourceLink,
} from './index.style'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addSimpleNotification } from '../../store/actions/notifications'
import { useTheme } from 'styled-components'
import { ReactionOperatorIcon } from 'components/ReactionViewer/index.style'
import MoleculeViewer from 'components/MoleculeViewer'
import {
  setOpenReactionsDialog,
  setReactionsDialogConfig,
} from 'store/actions/reactionSearch'
import CustomButton from 'components/common/customButton'
import { ArrowDownSvg } from 'components/CustomSelect/index.style'
import CustomScrollbar from 'components/CustomScrollbar'
import { REACTIONS_PAGINATION_LIMIT } from 'store/sagas/utils/config'
import { Column } from 'components/Search/SearchHistory/index.style'
import { Row } from 'components/Applicability/index.style'

const SearchReactionsCard = ({ data, idx }) => {
  const { id, reactants, products, conditions } = data || {}

  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const paginationState = useSelector((state) => state.search.pagination)
  const highlighted_molecule_id = useSelector(
    (state) => state.reactionsSearch.compound_id
  )
  const { activePage } = paginationState || {}
  const reactionRef = useRef(null)
  const [showMore, setShowMore] = useState(false)
  const showMoreAvailable = conditions?.length > 2
  const handleOpenDialog = useCallback(
    ({ e, condition, idx }) => {
      e.preventDefault()
      dispatch(setOpenReactionsDialog(true))
      dispatch(
        setReactionsDialogConfig({
          data: condition,
          idx,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  return (
    <>
      <CardWrapper
        isFullMode={false}
        id="reaction-search-wrapper"
        padding="0.5rem"
        gap="1rem"
        showMore={showMore}
        showMoreAvailable={showMoreAvailable}
      >
        <CardContent ref={reactionRef}>
          <CardHeader
            id="search-reactions-card-header"
            padding="0.5rem 0.5rem 0.75rem 0.5rem"
            maxHeight="2rem"
          >
            <Headline
              className="header-title"
              fontWeight={500}
              style={{ maxWidth: 'calc(100% - 4.5rem)' }}
            >
              {`${t('filter.reaction.reaction')} ${
                !activePage || activePage === 1
                  ? idx + 1
                  : (activePage - 1) * REACTIONS_PAGINATION_LIMIT + idx + 1
              }`}
            </Headline>
          </CardHeader>
          {/* НАЧАЛО РЕАКЦИИ */}
          <CustomScrollbar
            noScrollY={true}
            noScrollX={false}
            style={{ height: '11rem' }}
            disableTrackXWidthCompensation={true}
          >
            <CardReaction id="search-reactions-card-reaction">
              <Container>
                <ReactBlock>
                  {reactants?.map((el, idx) => {
                    return (
                      <Fragment key={`l-${idx}`}>
                        <MoleculeViewer
                          fixedSize={true}
                          width={190}
                          height={170}
                          smiles={el?.smiles}
                          noDelete
                          noEdit
                          noMenu
                          noHover={true}
                          noContainerHover={true}
                          noClick={true}
                          highlight_smiles={
                            el?.id === highlighted_molecule_id
                              ? el?.smiles
                              : null
                          }
                        />
                        {idx !== reactants.length - 1 && (
                          <SvgWrapper size="2rem">
                            <ReactionOperatorIcon
                              iconType="add"
                              size="2rem"
                              color={theme.colors.icons.accentPrimary}
                            />
                          </SvgWrapper>
                        )}
                      </Fragment>
                    )
                  })}

                  <SvgWrapper size="2rem">
                    <ReactionOperatorIcon
                      iconType="reactionResultIcon"
                      size="2rem"
                      color={theme.colors.icons.accentPrimary}
                    />
                  </SvgWrapper>

                  {products?.map((el, idx) => {
                    return (
                      <Fragment key={el.id ?? `r-${idx}`}>
                        <MoleculeViewer
                          fixedSize={true}
                          width={190}
                          height={170}
                          smiles={el?.smiles}
                          noDelete
                          noEdit
                          noMenu
                          noHover
                          noContainerHover={true}
                          noClick={true}
                          highlight_smiles={
                            el?.id === highlighted_molecule_id
                              ? el?.smiles
                              : null
                          }
                        />
                        {idx !== products.length - 1 && (
                          <SvgWrapper size="2rem">
                            <ReactionOperatorIcon
                              iconType="add"
                              size="2rem"
                              color={theme.colors.icons.accentPrimary}
                            />
                          </SvgWrapper>
                        )}
                      </Fragment>
                    )
                  })}
                </ReactBlock>
              </Container>
            </CardReaction>
          </CustomScrollbar>
          {/* КОНЕЦ РЕАКЦИИ */}
          <CardMethodsWrapper id="search-reactions-card-conditions">
            {conditions?.map((condition, index) => {
              const {
                id,
                yield_percents,
                is_open_reaction_database,
                sources,
                elements,
              } = condition || {}

              return (
                <CardMethod
                  key={id}
                  onClick={(e) =>
                    handleOpenDialog({
                      e,
                      condition,
                      idx: index + 1,
                    })
                  }
                >
                  <Row justifyContent="space-between">
                    <Label fontWeight={theme.fontWeight.semibold}>{`${t(
                      'filter.reaction.method'
                    )} ${index + 1}`}</Label>
                    <MethodYield>
                      <Caption
                        lineHeight={theme.text[14]}
                        fontWeight={theme.fontWeight.medium}
                      >{`${t('filter.reaction.yield')}: ${
                        yield_percents
                          ? `${yield_percents}%`
                          : `${t('common.unknown')}`
                      }`}</Caption>
                    </MethodYield>
                  </Row>
                  <Column gap="0.25rem" flexGrow="1">
                    <Subheadline fontWeight={400}>
                      {t('filter.reaction.reaction_conditions')}:
                    </Subheadline>
                    {!elements?.length && (
                      <Subheadline
                        fontWeight={theme.fontWeight.light}
                        color={theme.colors.statuses.error}
                      >
                        {t('common.missing')}
                      </Subheadline>
                    )}
                    {!!elements?.length && Array.isArray(elements) && (
                      <ElementsWrapper>
                        <CardAbstract
                          lineClamp="4"
                          maxHeight="none"
                          key={`el-${idx}`}
                        >
                          <Label color={theme.colors.text.secondary}>
                            {elements.join(', ')}
                          </Label>
                        </CardAbstract>
                      </ElementsWrapper>
                    )}
                  </Column>
                  <Option
                    onClick={(e) =>
                      handleOpenDialog({ e, condition, idx: index + 1 })
                    }
                  >
                    <Subheadline color="inherit" fontWeight={400}>
                      {t('common.full_view')}
                    </Subheadline>
                    <Icon iconType={'arrowRight'} size="1rem" />
                  </Option>
                  <Row justifyContent="space-between">
                    <TypeRow style={{ flexGrow: 1 }}>
                      {sources?.length && (
                        <>
                          <Subheadline fontWeight={400}>
                            {t('filter.reaction.lit_link')}:
                          </Subheadline>
                          <TypeNumber
                            fontWeight={400}
                            isLink={true}
                            width="100%"
                          >
                            <Source key="source-initial">
                              <SourceLink
                                target="_blank"
                                href={`https://doi.org/${sources?.[0]}`}
                                rel="noreferrer"
                                maxWidth="15rem"
                                whiteSpace="nowrap"
                              >
                                {sources?.[0]}
                              </SourceLink>
                              <Icon
                                className="copy-name-icon"
                                iconType="copy"
                                size="1rem"
                                dataTest="reaction-card-copy-btn"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  navigator.clipboard.writeText(sources?.[0])
                                  dispatch(
                                    addSimpleNotification(
                                      `notification.link_copied`
                                    )
                                  )
                                }}
                              />
                              {sources?.length > 1 && (
                                <MethodYield style={{ marginLeft: '0.25rem' }}>
                                  <Caption
                                    pointer={true}
                                    onClick={(e) =>
                                      handleOpenDialog({
                                        e,
                                        condition,
                                        idx: index + 1,
                                      })
                                    }
                                    lineHeight={theme.text[14]}
                                    fontWeight={theme.fontWeight.medium}
                                  >{`+ ${sources.length - 1}`}</Caption>
                                </MethodYield>
                              )}
                            </Source>
                          </TypeNumber>
                        </>
                      )}
                    </TypeRow>
                    <SourcesWrapper
                      data-tooltip-id={`card-method-sources-tip-${idx}`}
                    >
                      <Label color={theme.colors.text.tertiary}>
                        {t('filter.reaction.sources')}
                      </Label>
                      <Icon iconType="info" size="1rem" />
                    </SourcesWrapper>
                    <ReactTooltip
                      id={`card-method-sources-tip-${idx}`}
                      className="c-tooltip c-tooltip-base"
                      place="top"
                      positionStrategy="fixed"
                    >
                      {`${t(
                        'filter.reaction.material_taken_from_open_sources'
                      )}`}
                    </ReactTooltip>
                  </Row>
                </CardMethod>
              )
            })}
          </CardMethodsWrapper>
        </CardContent>
        {conditions.length > 2 && (
          <ShowMoreBlock>
            <CustomButton
              type="text"
              width="100%"
              gap="0.25rem"
              onClick={() => {
                setShowMore((prev) => !prev)
              }}
            >
              {t(`filter.reaction.${showMore ? 'show_less' : 'show_more'}`)}

              <ArrowDownSvg iconType="arrowDown" open={showMore} size="1rem" />
            </CustomButton>
          </ShowMoreBlock>
        )}
      </CardWrapper>
    </>
  )
}

export default SearchReactionsCard
