/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { MoleculePage } from 'pages/MoleculePage'
import CustomInput from 'components/common/customInput'
import { CustomDialog } from 'components/common/customDialog'
import { TitleTertiary } from 'components/common/text/index.style'
import {
  Warning,
  Container,
  LoadButton,
  ButtonNext,
  SearchBlock,
  KetcherBlock,
  ButtonsBlock,
  TitleBlock,
} from './index.style'
import Ketcher from '../Ketcher'
import { FREEMOL_MAX } from './config/config'
import { addNotification } from '../../store/actions/notifications'
import { fetch, store } from '../../services/rest'
import MoleculeSearchPanelRest from '../MoleculeSearchPanelRest'
import { settingsFreeMolInc } from '../../store/actions/settings'
import { useLocation } from 'react-router-dom'
import { setEditorIndividualId } from 'store/actions/molecule'
import CustomButton from 'components/common/customButton'

const IndividualPage = ({
  match,
  search,
  authId,
  freeMol,
  settingsFreeMolInc,
  setEditorIndividualId,
}) => {
  const [smiles, setSmiles] = useState('')
  const [showKetcher, setShowKetcher] = useState(true)
  const [showInd, setShowInd] = useState(false)
  const [showSmiles, setShowSmiles] = useState(false)
  const [tempSmilesString, setTempSmilesString] = useState('')
  const [ketcherSmiles, setKetcherSmiles] = useState('')

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const location = useLocation()
  const locationStringSplitted = location?.pathname?.split('moleditor/')
  const smilesFromURL = locationStringSplitted[1] ?? null

  const checkMoleculeOwnID = async () => {
    try {
      if (!smilesFromURL && !ketcherSmiles) return
      if (ketcherSmiles) {
        const validateSmilesData = await store(`/validate_smiles`, {
          smiles: ketcherSmiles,
        })
        const { data: validateData, status } = validateSmilesData || {}
        if (status === 200 && validateData?.smiles) {
          const { data } = await store(`/get_structure_by_default_view`, {
            text: validateData?.smiles,
          })
          if (data?.id) {
            dispatch(setEditorIndividualId(data?.id ? data.id : null))
          }
        }
        return
      }
      if (smilesFromURL) {
        const { data } = await store(`/get_structure_by_default_view`, {
          text: decodeURIComponent(smilesFromURL),
        })
        data?.id && dispatch(setEditorIndividualId(data.id))
      }
    } catch (err) {
      console.log('Error while fetch molecule data: ', err)
    }
  }
  useEffect(() => {
    checkMoleculeOwnID()
  }, [])

  const handleData = async (s) => {
    if (s) {
      setSmiles(s)
      settingsFreeMolInc()
      handleCloseKetcher()
    } else {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notifications.enterMolecule',
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }
      dispatch(addNotification(notify))
    }
  }

  const fetchInchi = async (smiles) => {
    const sm = encodeURIComponent(smiles)
    try {
      const { data } = await fetch(`/convert_smiles_to_inchi?smiles=${sm}`)
      if (!data.error) {
        return {
          canonizedSmiles: data.smiles,
          inchi: data.inchi,
          inchiKey: data.inchiKey,
        }
      }
    } catch (err) {
      return
    }
  }

  useEffect(() => {
    ;(async () => {
      if (match.params.smiles) {
        const smilesFromQuery = decodeURIComponent(match.params.smiles)
        const i = await fetchInchi(smilesFromQuery)
        if (i && i.canonizedSmiles) {
          setSmiles(i.canonizedSmiles)
        } else {
          const id = uuidv4()
          const notify = {
            id,
            name: 'notification.error',
            notification_type: 'error',
            autoRemove: true,
            timeout: 5000,
          }
          dispatch(addNotification(notify))
        }
      }
    })()
  }, [])

  useEffect(() => {
    const loadFromId = async (input) => {
      try {
        const { data: fetchData } = await fetch(`/molecule/${input}`, 2)
        const data = fetchData?.result || {}
        if (data.smiles) {
          setSmiles(data.smiles)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (search && search.length > 0) {
      loadFromId(search[0])
    }
  }, [search])

  useEffect(() => {
    !ketcherSmiles && smiles && setSmiles('')
  }, [ketcherSmiles])

  useEffect(() => {
    const interval = setInterval(async () => {
      const newSmiles = await global?.ketcher?.getSmiles(true)
      setKetcherSmiles(newSmiles)
    }, 500)

    return () => clearInterval(interval)
  }, [])

  const handleCloseKetcher = () => {
    setShowKetcher(false)
    setShowInd(true)
  }

  const handleCloseSmilesEnter = () => {
    setTempSmilesString('')
    setShowSmiles(false)
  }

  const handleOpenSmilesEnter = () => {
    setShowSmiles(true)
  }

  const handleSetTempSmiles = () => {
    setSmiles(tempSmilesString)
    handleCloseSmilesEnter()
  }

  const closeWithSave = async () => {
    if (global.ketcher && !!ketcherSmiles) {
      await checkMoleculeOwnID()
      setShowKetcher(false)
      setTimeout(() => {
        setSmiles(ketcherSmiles)
        setShowInd(true)
      })
    }
  }

  if (!authId && freeMol > FREEMOL_MAX) {
    return <Warning>{t('individual.warn_text')}</Warning>
  }

  const handleBackToMolEditor = () => {
    setShowInd(false)
    setShowKetcher(true)
  }

  return (
    <Container id="individual-page-container">
      {showKetcher && (
        <KetcherBlock>
          <TitleBlock>{t('promo.molecular_editor_title')}</TitleBlock>
          <SearchBlock>
            <MoleculeSearchPanelRest />
            <ButtonsBlock>
              <CustomButton
                type="accent"
                size="big"
                gap="0.25rem"
                onClick={handleOpenSmilesEnter}
              >
                <Icon iconType="smiles" />
                <span>{t('individual.smiles_btn')}</span>
              </CustomButton>
              <CustomButton
                type="accent"
                size="big"
                gap="0.25rem"
                onClick={closeWithSave}
                disabled={!ketcherSmiles}
              >
                <Icon iconType="calculate" />
                <span>{t('ketcher.calc_btn')}</span>
              </CustomButton>
            </ButtonsBlock>
          </SearchBlock>
          <Ketcher
            handleData={handleData}
            closeKetcher={() => {}}
            smiles={smiles}
            noDialog
            showSmiles={showSmiles}
          />
        </KetcherBlock>
      )}

      {showInd && (
        <MoleculePage
          {...{ smiles }}
          onGoBackToMolEditor={handleBackToMolEditor}
        />
      )}
      {showSmiles && (
        <CustomDialog onClose={handleCloseSmilesEnter} gap="24px" width="372px">
          <TitleTertiary fontWeight={500}>
            {t('individual.smiles_btn')}
          </TitleTertiary>
          <CustomInput
            onChange={setTempSmilesString}
            value={tempSmilesString}
            placeholder="SMILES"
            withClearButton
          />
          <LoadButton
            type="accent"
            width="100%"
            color="#2D3745"
            disabled={!tempSmilesString}
            onClick={!tempSmilesString ? () => {} : handleSetTempSmiles}
          >
            {t('individual.set')}
          </LoadButton>
        </CustomDialog>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    search: state.crud.search,
    freeMol: state.settings.freeMol,
    authId: state.auth.userdata.id,
  }
}

const mapDispatchToProps = {
  settingsFreeMolInc,
  setEditorIndividualId,
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualPage)
