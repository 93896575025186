export const PRIVACY_POLICY_LINK_RU = 'https://syntelly.ru/privatepolicy'
export const PRIVACY_POLICY_LINK_EN = 'https://syntelly.com/privatepolicy'
export const USER_AGREEMENT_RU = 'https://syntelly.ru/useragreement'
export const USER_AGREEMENT_EN = 'https://syntelly.com/useragreement'
export const PROGRESS_VALUE = 12.5
export const TEST_PERIOD = 15
export const SITE_KEY = '6LfwTr0pAAAAAO9kVY6a1q1a8vPcgUvA424bItwG'
export const FULL_FIRST_STEP_PROGRESS = 50
export const FULL_SURVEY_STEP_PROGRESS = 100
export const MIN_NUMBER_PHONE_LENGTH = 11
