import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import CustomButton from 'components/common/customButton'
import {
  loadCategories,
  loadMolecule,
  loadServices,
  loadRuIupac,
  resetRuIupac,
  resetMolecule,
  setEditorIndividualId,
  editMoleculeNote,
  setMoleculeNoteDialogId,
} from 'store/actions/molecule'
import {
  CATEGORIES_ID,
  DEFAULT_EXPANDED_CATEGORY_ID,
  DIALOG_MODE_SIZE,
  filterCategories,
  getCategoryKey,
  getMainId,
  scrollToCategory,
} from '../config/config'
import LoaderRestyling from 'components/LoaderRestyling'
import {
  ButtonContent,
  Container,
  Footer,
  LoaderContainer,
  Logo,
  MoleculePageActionsHeader,
  MoleculeViewerWrapper,
  Navigation,
  NavigationItem,
  NavigationItemMarker,
  NavigationItemName,
  Properties,
  PropertiesWrapper,
  References,
  Structure,
  WrapperContainer,
} from './index.style'
import MoleculeViewer from 'components/MoleculeViewer'
import Icon from 'components/Icon'
import { copyToClipboard, generatePdf } from 'utils/common/common'
import { Subheadline, TitleSecondary } from 'components/common/text/index.style'
import { ObservableCategory } from '../observableCategory'
import { useResize } from 'hooks/useResize'
import './index.css'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import { addSimpleNotification } from '../../../store/actions/notifications'
import history from '../../../services/history'
import { updateCrudState } from 'store/actions/crud'
import { updateSearchState } from 'store/actions/search'
import { loadApplicability } from 'store/actions/molecule'
import { getWrapperWidth } from './utils/utils'
import MoleculeLinks from 'components/MoleculeLinks'
import CustomSnackbar from 'components/HotTip/index'
import ActionButtons from '../ActionButtons'
import BasketsListModal from 'components/BasketsListModal'
import { addMolecule } from 'store/actions/crud'
import { setStorage } from 'utils/storage/storage'
import {
  handleShowContextMenu,
  updateDisabledActions,
} from 'store/actions/contextMenu'
import CustomScrollbar from 'components/CustomScrollbar'
import { useLocation } from 'react-router-dom'
import { setFilterConfig } from 'store/actions/filter'
import { useTheme } from 'styled-components'
import ApplicabilityInner from 'components/Applicability/ApplicabilityInner'
import MoleculeNoteDialog from 'components/MoleculeNoteDialog'
import { useOverflow } from 'hooks/useOverflow'

export const Molecule = ({
  categories,
  services,
  properties,
  match,
  id,
  smiles,
  basketId,
  isFullMode = true,
  ruIupac,
  resetRuIupac,
  applicability,
  loadApplicability,
  pathname,
  resetMolecule,
  addMolecule,
  onGoBackToMolEditor,
  individualId,
  addSimpleNotification,
  contextMenuState,
  updateDisabledActions,
  isDialog = false,
  noteConfig,
  noteDialogId,
  setMoleculeNoteDialogId,
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const logoImage = useGetLogoImage()
  const location = useLocation()
  const isMolEditorPage = pathname?.includes('moleditor')
  const isMolIndividualPage = pathname?.includes('molecule/')
  const isShowBackBtn =
    (isFullMode && history?.location?.state?.prev) || isMolEditorPage
  const [extended, setIsExtended] = useState([DEFAULT_EXPANDED_CATEGORY_ID])
  const [pdfGenerationStarted, setPdfGeneration] = useState(false)
  const [isAddMoleculeDialogOpen, setIsAddMoleculeDialogOpen] = useState(false)

  const textRef = useRef(null)
  const containerRef = useRef(null)

  const isOverflow = useOverflow(textRef, containerRef)

  const { alias, note, basketId: baskId } = noteConfig

  const setExtended = (category_id) => {
    if (extended.includes(category_id)) {
      const updated = extended.filter((el) => el !== category_id)
      return setIsExtended(updated)
    }
    setIsExtended([...extended, category_id])
    setActiveCategoryId(category_id)
    setTimeout(() => {
      scrollToCategory(category_id)
    })
  }

  const { loading: categoriesLoading, data: categoriesData } = categories
  const { loading: servicesLoading, data: servicesData } = services
  const { loading, error, sources, isNeedHidePred } = properties

  const { show: isApplicabilityShow, data: applicabilityData } = applicability

  const [isDownloadMenuShow, setIsDownloadMenuShow] = useState(false)

  const [mainId, setMainId] = useState(null)
  const [activeCategoryId, setActiveCategoryId] = useState(
    DEFAULT_EXPANDED_CATEGORY_ID
  )
  const [visibleCategories, setVisibleCategories] = useState([])
  const [moleculeViewerSize, setMoleculeViewerSize] = useState(
    isFullMode ? 0 : DIALOG_MODE_SIZE
  )
  const [data, setData] = useState(null)

  const disabledActions = {
    download_png: !data?.structural?.smiles,
    download_pdf:
      loading ||
      categoriesLoading ||
      servicesLoading ||
      (i18n.language === 'ru' && !ruIupac),
    download_mol: !data?.structural?.smiles,
  }

  useEffect(() => {
    return () => {
      resetRuIupac()
      if (isFullMode) resetMolecule()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!categoriesData || categoriesData.length === 0) {
      dispatch(loadCategories())
    }

    if (!servicesData || servicesData.length === 0) {
      dispatch(loadServices())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    setMainId(getMainId(id, match, smiles))
  }, [id, match, smiles])

  useEffect(() => {
    if (mainId && !data && !servicesLoading) {
      dispatch(loadMolecule({ mainId: individualId ?? mainId, basketId }))
      dispatch(setEditorIndividualId(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainId, dispatch, servicesLoading])

  useEffect(() => {
    properties?.data?.smiles && setData(properties.data)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties?.data])

  useEffect(() => {
    if (data?.iupac) dispatch(loadRuIupac({ iupac: data?.iupac }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.iupac])

  useEffect(() => {
    if (data?.structural?.smiles) loadApplicability(data.structural.smiles)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.structural?.smiles])

  useEffect(() => {
    if (contextMenuState?.menu === 'moleculeDownloadMenu') {
      updateDisabledActions(disabledActions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.structural?.smiles,
    loading,
    categoriesLoading,
    servicesLoading,
    ruIupac,
    i18n.language,
  ])

  const ref = useRef()
  const { width: moleculeViewerContainerWidth } = useResize(ref)

  useEffect(() => {
    isFullMode &&
      data?.structural?.smiles &&
      setMoleculeViewerSize(ref?.current?.offsetWidth)
  }, [data?.structural?.smiles, isFullMode])

  useEffect(() => {
    isFullMode &&
      moleculeViewerContainerWidth &&
      setMoleculeViewerSize(moleculeViewerContainerWidth)
  }, [moleculeViewerContainerWidth, isFullMode])

  const downloadPdf = async () => {
    setPdfGeneration(true)
  }

  useEffect(() => {
    if (pdfGenerationStarted) {
      generatePdf({
        id: 'molecule-page-container',
        classForPrint: 'molecule-container-print',
        dataCanvas: 'mol-viewer-canvas',
        shouldClone: true,
        fileName: `syntelly-molecule-${id}`,
      })
      setPdfGeneration(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfGenerationStarted])

  const filteredCategories = useMemo(
    () => filterCategories(categoriesData, data, isNeedHidePred),
    [categoriesData, data, isNeedHidePred]
  )

  const changeActiveCategory = (category_id) => {
    setActiveCategoryId(category_id)
    !extended.includes(category_id) && setExtended(category_id)
    setTimeout(() => {
      scrollToCategory(category_id)
    })
  }

  const changeCategoryVisibility = (category_id, inView) => {
    if (inView) {
      setVisibleCategories(
        Array.from(new Set([...visibleCategories, category_id]))
      )
      return
    }

    const updated = [...visibleCategories].filter((c) => c !== category_id)
    setVisibleCategories(updated)
    if (category_id === activeCategoryId) {
      const activeCategory = filteredCategories.find(({ category_id }) =>
        updated.includes(category_id)
      )

      activeCategory && setActiveCategoryId(activeCategory.category_id)
    }
  }

  const handleLinkClick = (value) => {
    if (value === 'literature') {
      const smiles = data?.structural?.smiles
      setStorage('redirect_search_text', smiles)
      window.open('/search')
    }
  }

  const handleGoBack = () => {
    if (isMolEditorPage) return onGoBackToMolEditor()
    if (isMolIndividualPage) {
      const { searchState, crudState, filterConfig } =
        location?.state || {}
      dispatch(goBack())
      searchState && dispatch(updateSearchState(searchState))
      crudState && dispatch(updateCrudState(crudState))
      filterConfig && dispatch(setFilterConfig(filterConfig))
      return
    }
    dispatch(goBack()) 
  }

  if (error) {
    return (
      <Structure
        isFullMode={isFullMode}
        isShowBackBtn={isShowBackBtn}
        className="molecule-page-structure"
        ref={containerRef}
      >
        {isShowBackBtn && (
          <CustomButton
            type="text"
            onClick={handleGoBack}
            className={'hidden-for-print'}
          >
            <ButtonContent>
              <Icon iconType={'arrowLeft'} size="1rem" />
              <div>{t('molviewer.back_to_results')}</div>
            </ButtonContent>
          </CustomButton>
        )}
        <TitleSecondary>
          {t('molviewer.failed_to_load_molecule_data')}
        </TitleSecondary>
      </Structure>
    )
  }

  if (!data?.structural?.smiles) {
    return (
      <LoaderContainer>
        <LoaderRestyling />
      </LoaderContainer>
    )
  }
  const { width, maxWidth } = getWrapperWidth(isFullMode, isApplicabilityShow)

  const toggleMenu = (e) => {
    if (contextMenuState.menu === 'moleculeDownloadMenu') return
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'moleculeDownloadMenu',
        className: 'context-menu-short',
        item: {
          num: id,
          smiles: smiles ?? data?.structural?.smiles,
          text: '',
          onChange: () => undefined,
          ref,
          downloadPDFHandler: downloadPdf,
          disabledActions,
        },
      })
    )
  }

  const handleAddMoleculeToBasket = (ids, newBasketName) => {
    const smilesArray = [
      {
        smiles: data.structural.smiles,
      },
    ]

    if (ids?.length) {
      ids.forEach((el) => addMolecule(el.id, smilesArray, false, el.name, t))
    } else {
      addMolecule(-1, smilesArray, false, newBasketName, t)
    }
  }

  const toggleShowNoteDialog = () =>
    setMoleculeNoteDialogId(noteDialogId ? null : id)

  const iconButtonsConfig = [
    {
      icon: 'add',
      text: t('molecule_viewer.to_dataset'),
      disabled: false,
      onClick: () => setIsAddMoleculeDialogOpen(true),
      id: 'add-structure-to-dataset',
    },
    {
      icon: 'share',
      text: t('molecule_viewer.link'),
      disabled: !id,
      onClick: () => {
        copyToClipboard(`${window.location.origin}/molecule/${id}`)

        addSimpleNotification('notification.link_copied')
      },
      id: 'share-structure',
    },
    {
      icon: 'upload',
      text: t('molecule_viewer.download'),
      disabled: false,
      onClick: toggleMenu,
      id: 'download-structure',
    },
    {
      icon: 'note',
      text: note
        ? t('molecule_viewer.open_note')
        : alias
        ? t('molecule_viewer.edit_name_or_note')
        : t('molecule_viewer.write_name_or_note'),
      disabled: false,
      onClick: toggleShowNoteDialog,
      hidden: !baskId,
      id: note ? 'open-note' : 'edit-note',
    },
  ]

  const handleEditNote = async (params) => {
    dispatch(
      editMoleculeNote({
        molId: id,
        basketId: baskId,
        params,
        hasNote: alias !== null || note !== null,
        isSingleMolecule: true,
      })
    )
  }

  return (
    <>
      {!isFullMode && (
        <MoleculePageActionsHeader data-test="molecule-page-actions-header">
          <CustomButton
            type={'text'}
            disabled={!id}
            onClick={() => window.open(`/molecule/${id}`)}
          >
            <ButtonContent>
              <div>{t('moleculeslist.to_structure_page')}</div>
              <Icon iconType={'arrowRight'} size="1rem" />
            </ButtonContent>
          </CustomButton>

          <ActionButtons
            buttonsConfig={iconButtonsConfig}
            {...{ isDownloadMenuShow, note }}
            margin="0 0 0 0.75rem"
          />
        </MoleculePageActionsHeader>
      )}
      <Container
        isFullMode={isFullMode}
        id="molecule-page-container"
        className="molecule-page-container"
      >
        <Logo src={logoImage} className="molecule-page-logo" />
        <Structure
          isFullMode={isFullMode}
          isShowBackBtn={isShowBackBtn}
          className="molecule-page-structure"
          ref={containerRef}
        >
          {isShowBackBtn && (
            <CustomButton
              type="text"
              onClick={handleGoBack}
              className={'hidden-for-print'}
            >
              <ButtonContent>
                <Icon iconType={'arrowLeft'} size="1rem" />
                <div>{t('molviewer.back_to_results')}</div>
              </ButtonContent>
            </CustomButton>
          )}
          {isFullMode && (
            <>
              <TitleSecondary ref={textRef} data-tooltip-id="molecule-alias">
                {alias || t('molviewer.molecule_page')}
              </TitleSecondary>
              {isOverflow && (
                <ReactTooltip
                  id="molecule-alias"
                  place={'bottom'}
                  className="c-tooltip c-tooltip-base c-tooltip-z-max c-tooltip-molecule-page-note"
                  classNameArrow="c-tooltip-arrow"
                >
                  {alias}
                </ReactTooltip>
              )}
            </>
          )}
          <MoleculeViewerWrapper
            isFullMode={isFullMode}
            ref={ref}
            className="molecule-page-viewer-wrapper"
          >
            <MoleculeViewer
              width={moleculeViewerSize}
              height={moleculeViewerSize}
              smiles={data?.structural?.smiles}
              handleClick={() => {}}
              onShowProps={() => {}}
              noDelete
              noEdit
              cursor={'default'}
              type="no-border"
              isFullMode={isFullMode}
              noPadding
              showId
              num={id || data?.baseID}
              isGoToSectionMenu
              noContainerHover={true}
              referencesCount={data?.structural?.references_in_documents}
              isFullWidth={isMolIndividualPage}
              isDialog={isDialog}
            />
          </MoleculeViewerWrapper>
          <References className="molecule-page-references">
            <MoleculeLinks
              isDialog={isDialog}
              onLinkActionClick={handleLinkClick}
              withBigButtons={true}
              referencesCount={data?.structural?.references_in_documents}
            />
          </References>
        </Structure>
        <WrapperContainer
          width={width}
          maxWidth={maxWidth}
          className="molecule-page-wrapper-container"
          isFullMode={isFullMode}
          isShowBackBtn={isShowBackBtn}
        >
          {/* {isFullMode && <Applicability isFullMode={isFullMode} />} */}

          {isFullMode && (
            <ActionButtons
              buttonsConfig={iconButtonsConfig}
              {...{ isDownloadMenuShow, note }}
              margin="0 0 0.5rem 0"
            />
          )}
          <PropertiesWrapper className="molecule-page-properties-wrapper">
            {!pdfGenerationStarted ? (
              <CustomScrollbar
                disableTrackYWidthCompensation={true}
                className="margin--8-right"
              >
                <Properties
                  isFullMode={isFullMode}
                  id={CATEGORIES_ID}
                  className="molecule-page-properties"
                >
                  {filteredCategories.map(
                    ({ category_id, category_name, inner }) => (
                      <ObservableCategory
                        category_id={category_id}
                        category_name={category_name}
                        inner={
                          category_name !== 'structural'
                            ? inner
                            : [
                                {
                                  category_id: 0,
                                  category_title: 'Syntelly ID',
                                  name: 'baseID',
                                },
                                ...inner,
                              ]
                        }
                        data={
                          i18n.language === 'en'
                            ? data
                            : { ...data, iupac: ruIupac }
                        }
                        sources={sources}
                        isFullMode={isFullMode}
                        extended={extended}
                        setExtended={setExtended}
                        changeCategoryVisibility={changeCategoryVisibility}
                        key={category_id}
                        applicabilityData={applicabilityData}
                        isApplicabilityShow={isApplicabilityShow}
                      />
                    )
                  )}
                </Properties>
              </CustomScrollbar>
            ) : (
              <Properties
                isFullMode={isFullMode}
                id={CATEGORIES_ID}
                className="molecule-page-properties"
              >
                {filteredCategories.map(
                  ({ category_id, category_name, inner }) => (
                    <ObservableCategory
                      category_id={category_id}
                      category_name={category_name}
                      inner={
                        category_name !== 'structural'
                          ? inner
                          : [
                              {
                                category_id: 0,
                                category_title: 'Syntelly ID',
                                name: 'baseID',
                              },
                              ...inner,
                            ]
                      }
                      data={
                        i18n.language === 'en'
                          ? data
                          : { ...data, iupac: ruIupac }
                      }
                      sources={sources}
                      isFullMode={isFullMode}
                      extended={extended}
                      setExtended={setExtended}
                      changeCategoryVisibility={changeCategoryVisibility}
                      key={category_id}
                      applicabilityData={applicabilityData}
                      isApplicabilityShow={isApplicabilityShow}
                    />
                  )
                )}
              </Properties>
            )}
          </PropertiesWrapper>
        </WrapperContainer>
        {isFullMode && (
          <Navigation className="molecule-page-navigation">
            {filteredCategories.map(({ category_id, category_name }) => (
              <NavigationItem
                className="molecule-page-navigation-item"
                key={category_id}
                isActive={activeCategoryId === category_id}
                onClick={() => changeActiveCategory(category_id)}
              >
                <NavigationItemMarker
                  isActive={activeCategoryId === category_id}
                />
                <NavigationItemName>
                  <Subheadline
                    className="molecule-page-subheadline"
                    color={
                      activeCategoryId === category_id
                        ? theme.colors.text.accentPrimary
                        : theme.colors.text.secondary
                    }
                  >
                    {t(getCategoryKey(category_name))}
                  </Subheadline>
                </NavigationItemName>
              </NavigationItem>
            ))}
          </Navigation>
        )}
        <Footer className="molecule-page-footer">
          {t('pdf_print.footer')}
        </Footer>
      </Container>
      {isAddMoleculeDialogOpen && (
        <BasketsListModal
          onAgree={handleAddMoleculeToBasket}
          onClose={() => setIsAddMoleculeDialogOpen(false)}
          withPublic={false}
          withNew={true}
          actionText={t('molecule_viewer.add_to_dataset')}
          onlyOne
        />
      )}
      {!isDialog && (
        <CustomSnackbar
          withChildren={true}
          label="moleculeslist.applicability_indicator_label"
        >
          <ApplicabilityInner data-test="applicability-inner" asHotTip={true} />
        </CustomSnackbar>
      )}

      {noteDialogId === id && (
        <MoleculeNoteDialog
          {...{ alias, note }}
          onClose={toggleShowNoteDialog}
          onSaveNote={(alias, note) => handleEditNote({ alias, note })}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.molecule.categories,
    services: state.molecule.services,
    properties: state.molecule.properties,
    ruIupac: state.molecule.ruIupac,
    applicability: state.molecule.applicability,
    pathname: state.router.location.pathname,
    contextMenuState: state.contextMenu.contextMenuState,
    individualId: state.molecule.editorIndividualId,
    history: state.searchHistory.history,
    noteConfig: state.molecule.noteConfig,
    noteDialogId: state.molecule.moleculeNoteDialogId,
  }
}

const mapDispatchToProps = {
  loadCategories,
  loadServices,
  loadMolecule,
  loadRuIupac,
  resetRuIupac,
  loadApplicability,
  addMolecule,
  resetMolecule,
  addSimpleNotification,
  updateDisabledActions,
  setMoleculeNoteDialogId,
}

export default connect(mapStateToProps, mapDispatchToProps)(Molecule)
