import {
  RESET_RU_IUPAC,
  LOAD_RU_IUPAC_INIT,
  LOAD_SERVICES_INIT,
  LOAD_CATEGORIES_INIT,
  TOGGLE_APPLICABILITY,
  LOAD_MOLECULE_PROPS_INIT,
  LOAD_APPLICABILITY_INIT,
  OPEN_ADD_MOLECULE_DIALOG,
  CLOSE_ADD_MOLECULE_DIALOG,
  RESET_MOLECULE_PROPS,
  SET_EDITOR_INDIVIDUAL_ID,
  SET_BLUR_NON_APPLICABLE,
  EDIT_MOLECULE_NOTE,
  UPDATE_MOLECULE_NOTE_CONFIG,
  SET_MOLECULE_NOTE_DIALOG_ID,
} from 'store/constants/molecule'

export const loadCategories = () => {
  return {
    type: LOAD_CATEGORIES_INIT,
  }
}

export const loadServices = () => {
  return {
    type: LOAD_SERVICES_INIT,
  }
}

export const loadMolecule = ({ mainId, basketId }) => {
  return {
    type: LOAD_MOLECULE_PROPS_INIT,
    mainId,
    basketId,
  }
}
export const resetMolecule = () => {
  return {
    type: RESET_MOLECULE_PROPS,
  }
}

export const loadRuIupac = ({ iupac }) => {
  return {
    type: LOAD_RU_IUPAC_INIT,
    iupac,
  }
}

export const resetRuIupac = () => {
  return {
    type: RESET_RU_IUPAC,
  }
}

export const toggleApplicability = () => {
  return {
    type: TOGGLE_APPLICABILITY,
  }
}

export const loadApplicability = (smiles) => {
  return {
    type: LOAD_APPLICABILITY_INIT,
    smiles,
  }
}

export const openAddMoleculeDialog = (id) => {
  return {
    type: OPEN_ADD_MOLECULE_DIALOG,
    id,
  }
}
export const closeAddMoleculeDialog = () => {
  return {
    type: CLOSE_ADD_MOLECULE_DIALOG,
  }
}
export const setEditorIndividualId = (id) => {
  return {
    type: SET_EDITOR_INDIVIDUAL_ID,
    id,
  }
}
export const setBlurNonApplicable = (value) => {
  return {
    type: SET_BLUR_NON_APPLICABLE,
    value,
  }
}

export const setMoleculeNoteDialogId = (value) => {
  return {
    type: SET_MOLECULE_NOTE_DIALOG_ID,
    value,
  }
}

export const editMoleculeNote = ({
  molId,
  basketId,
  params,
  hasNote,
  isBestMatch,
  isSimilarResults,
  isSingleMolecule,
}) => {
  return {
    type: EDIT_MOLECULE_NOTE,
    molId,
    basketId,
    params,
    hasNote,
    isBestMatch,
    isSimilarResults,
    isSingleMolecule,
  }
}

export const updateMoleculeNoteConfig = (config) => {
  return {
    type: UPDATE_MOLECULE_NOTE_CONFIG,
    config,
  }
}
