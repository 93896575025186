import { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'

import LoaderRestyling from 'components/LoaderRestyling'
import { SITE_KEY } from 'components/RegistrationPageNew/config'

import { LoaderContainer } from './index.style'
import InfoWithProgress from '../InfoWithProgress'

const ReCaptchaStep = ({ onChangeRecaptcha }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      <InfoWithProgress
        showProgress={false}
        title={t('registration.confirm_not_a_robot')}
        description={t('registration.providing_quality_services')}
        additionalDescription={t('registration.protection')}
      />
      {isLoading && (
        <LoaderContainer>
          <LoaderRestyling />
        </LoaderContainer>
      )}
      <ReCAPTCHA
        hl={i18n.language}
        sitekey={SITE_KEY}
        onChange={onChangeRecaptcha}
        asyncScriptOnLoad={() => setIsLoading(false)}
      />
    </>
  )
}

export default ReCaptchaStep
