import {
  SET_LIT_DIALOG_CONFIG,
  SET_LIT_FILTER_CONFIG,
  SET_OPEN_LIT_DIALOG,
  SET_OPEN_LIT_FILTER,
  SET_LIT_FILTER_DOC_TYPE,
  SET_PREV_SEARCH_DATA_ID,
  SET_LITERATURE_LIST_VIEW,
  SET_LIT_PAGE_OPEN,
  SET_LIT_SYNTELLY_ID,
} from 'store/constants/literature'

const initialState = {
  filter: { open: false, config: {}, docType: '' },
  dialog: { open: false, config: {} },
  prevSearchDataId: null,
  listView: 'line',
  litPage: { open: false, config: {} },
  syntellyId: null,
}

const literatureReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_OPEN_LIT_FILTER:
      return {
        ...state,
        filter: { ...state.filter, open: payload.isOpen },
      }
    case SET_LIT_FILTER_CONFIG:
      return {
        ...state,
        filter: { ...state.filter, config: { ...payload.config } },
      }
    case SET_LIT_FILTER_DOC_TYPE:
      return {
        ...state,
        filter: { ...state.filter, docType: payload.docType },
      }
    case SET_OPEN_LIT_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: payload.isOpen,
        },
      }
    case SET_LIT_DIALOG_CONFIG:
      return {
        ...state,
        dialog: { ...state.dialog, config: { ...payload.config } },
      }
    case SET_PREV_SEARCH_DATA_ID:
      return {
        ...state,
        prevSearchDataId: payload.prevSearchDataId,
      }
    case SET_LITERATURE_LIST_VIEW:
      return {
        ...state,
        listView: payload.value,
      }

    case SET_LIT_PAGE_OPEN:
      return {
        ...state,
        litPage: { open: payload.open, config: { ...payload.config } },
      }
    case SET_LIT_SYNTELLY_ID:
      return {
        ...state,
        syntellyId: payload.syntellyId,
      }

    default:
      return state
  }
}

export default literatureReducer
