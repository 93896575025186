import { put, select, takeEvery } from 'redux-saga/effects'
import { store } from 'services/rest'
import { ADD_NOTIFICATION } from 'store/constants/notifications'
import { v4 as uuidv4 } from 'uuid'
import {
  SET_FEEDBACK_PAGINATION_CONFIG,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_INIT,
  APPLY_FEEDBACK_FILTER,
  GET_FEEDBACK_ERROR,
} from 'store/constants/feedbackForm'
import { getEndOfDay, getStartOfDay } from './utils/utils'

function* getFeedback({ limit = 24, offset = 0 }) {
  const filter = yield select((state) => state.feedback.filter)
  const preparedModules = filter?.modules?.map((el) => el?.id)
  const preparedFilter = {
    ...(filter?.theme !== 'all' ? { type_list: [filter?.theme] } : {}),
    ...(preparedModules?.length ? { module_list: preparedModules } : {}),
    ...(filter?.email ? { user_email: filter?.email } : {}),
    ...(filter?.date?.[0]
      ? { created_at_from: getStartOfDay(filter.date[0]) }
      : {}),
    ...(filter?.date?.[1]
      ? { created_at_to: getEndOfDay(filter.date[1]) }
      : {}),
  }
  const preparedSort = {
    [filter?.sort?.type]: filter?.sort?.direction || 'desc',
  }
  try {
    const { data } = yield store('/feedback/get', {
      params: {
        filter: preparedFilter,
        sort: preparedSort,
        limit,
        offset,
      },
    })
    yield put({
      type: GET_FEEDBACK_SUCCESS,
      data: data?.items,
    })

    yield put({
      type: SET_FEEDBACK_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.floor(+data?.total / limit) + 1,
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      text: e.message,
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
      needTranslateText: false,
    }
    yield put({
      type: GET_FEEDBACK_ERROR,
    })
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* getFeedbackWatcher() {
  yield takeEvery(GET_FEEDBACK_INIT, getFeedback)
}

export function* applyFeedbackFilterWatcher() {
  yield takeEvery(APPLY_FEEDBACK_FILTER, getFeedback)
}
