import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  ColoredSubheadline,
  Container,
  ExternalLink,
  Group,
  GroupedProperties,
  PropertySource,
  PropertyValue,
  CategorySort,
  CategorySortItem,
  PropertyRow,
  SynonymsHint,
  Wrapper,
  Synonyms,
  SynonymsTypeSelector,
  SynonymsList,
} from './index.style'
import {
  CategoryHeader,
  CategoryContent,
  KeyValueProperty,
  DescriptedPropertyKey,
} from './index.style'
import {
  Subheadline,
  TextBody,
  TextMedium,
} from 'components/common/text/index.style'
import {
  COPIED_PROPERTY_NAMES,
  POSSIBLE_ERR_VALUES_BY_GROUP,
  HEADERS_EXCEPTIONS,
  addSubTags,
  getCategoryId,
  getCategoryKey,
  getCustomHeadersDescription,
  getSourceByCategoryName,
  needHideProperty,
  CATEGORIES_WITH_DESC,
} from '../config/config'
import Icon from 'components/Icon'
import '../../common/tooltip/style.css'

import { copyToClipboard } from 'utils/common/common'
import { addSimpleNotification } from '../../../store/actions/notifications'
import { SYNONYMS_TYPES, toxySortConfig } from './config'
import ApplicabilityIndicator from 'components/applicability-indicator'
import { setBlurNonApplicable } from 'store/actions/molecule'
import { css, useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomBtnsScroller from 'components/common/customBtnsScroller'
import CustomTab from 'components/common/customTab'
import BlurableValue from './BlurableValue'
import ValueWithTooltip from './ValueWithTooltip'

export const Category = ({
  category_id,
  category_name,
  inner,
  data,
  sources,
  extended,
  setExtended,
  applicabilityData,
  isApplicabilityShow,
  isFullMode,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isExpanded = useMemo(
    () => extended.includes(category_id),
    [category_id, extended]
  )

  const [toxySortType, setToxySortType] = useState('all')
  const [synonymsType, setSynonymsType] = useState('names')
  const containerRef = useRef(null)

  const dispatch = useDispatch()

  const toggleIsExpanded = () => setExtended(category_id)

  const blurNonApplicable = useSelector(
    (store) => store.molecule.categories.blurNonApplicable
  )

  useEffect(() => {
    return () => dispatch(setBlurNonApplicable(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataByCategoryName = (name) => {
    const d = data?.[name]

    if (!d) return
    if (d && typeof d === 'object' && name !== 'drug') {
      const { value, interval, human_value, unit } = d

      return (
        <span>
          {value} {interval && `± ${interval}`}{' '}
          {human_value && `(${human_value})`} {unit}
        </span>
      )
    }

    if (d && name === 'rdkit_count_chemical_formula') {
      return addSubTags(d)
    }

    return d
  }

  const handleCopy = (name, value) => {
    copyToClipboard(value)
    let notifyName = ''
    if (name === 'smiles') notifyName = 'notification.smiles_copied'
    if (name === 'iupac') notifyName = 'notification.iupac_copy'
    if (name === 'baseID') notifyName = 'notification.baseid_copy'
    if (name === 'inchi') notifyName = 'notification.inchi_copy'
    if (name === 'inchiKey') notifyName = 'notification.inchi_key_copy'

    dispatch(addSimpleNotification(notifyName))
  }

  const handleChangeSortType = (value) => setToxySortType(value)

  const innerFiltered = useMemo(() => {
    if (category_name === 'toxy') {
      if (toxySortType === 'all') return inner
      return inner.filter((el) =>
        toxySortType === 'lethal_dose_models'
          ? el.name.includes('LD50') || el.name.includes('LDLo')
          : !el.name.includes('LD50') && !el.name.includes('LDLo')
      )
    }
    return inner
  }, [category_name, inner, toxySortType])

  if (
    category_name === 'synonyms' &&
    (!data.synonyms ||
      (!data.synonyms?.databases_ids?.length && !data.synonyms?.names?.length))
  ) {
    return (
      <Container
        id={getCategoryId(category_id)}
        isFullMode={isFullMode}
        style={{ position: 'relative' }}
      >
        <CategoryHeader
          className="molecule-category-header"
          isExpanded={isExpanded}
          onClick={toggleIsExpanded}
          noHoverStyles={true}
          isFullMode={isFullMode}
        >
          <TextBody fontWeight={500}>
            {t(getCategoryKey(category_name))}
          </TextBody>
        </CategoryHeader>
        <Subheadline
          color={theme.colors.text.secondary}
          fontWeight={400}
          className={'property-name'}
          style={{ marginTop: '0.25rem' }}
        >
          {t('molviewer.no_synonyms')}
        </Subheadline>
        <SynonymsHint
          data-tooltip-html={t('molviewer.why_that_tip')}
          data-tooltip-id="no-synonyms-tooltip"
          isFullMode={isFullMode}
        >
          <p>{t('molviewer.why_that')}</p>
          <Icon iconType="info" />
          <ReactTooltip
            id="no-synonyms-tooltip"
            className="c-tooltip c-tooltip-base c-tooltip-category-syn c-tooltip-z-max"
            classNameArrow="c-tooltip-arrow"
            place="top-end"
            positionStrategy={'fixed'}
          />
        </SynonymsHint>
      </Container>
    )
  }

  return (
    <Wrapper className="molecule-category-wrapper">
      <CustomBtnsScroller
        innerRef={containerRef}
        extraTopStyles={css`
          border-radius: 0.75rem 0.75rem 0 0;
        `}
        extraBottomStyles={css`
          border-radius: 0 0 0.75rem 0.75rem;
          bottom: 0;
        `}
      >
        <Container
          id={getCategoryId(category_id)}
          isFullMode={isFullMode}
          ref={containerRef}
          className="molecule-category-container"
        >
          <CategoryHeader
            className="molecule-category-header"
            isExpanded={isExpanded}
            onClick={toggleIsExpanded}
            isFullMode={isFullMode}
          >
            <TextBody fontSize={'0.75rem'} lineHeight={'1rem'} fontWeight={500}>
              {t(getCategoryKey(category_name))}
            </TextBody>
            <CustomButton
              type={isFullMode ? 'icon_secondary' : 'icon_primary'}
              defaultPadding="0.25rem"
              defaultColor={
                isExpanded
                  ? theme.colors.icons.accentPrimary
                  : theme.colors.icons.tertiary
              }
              borderRadius="0.375rem"
            >
              <Icon
                iconType={isExpanded ? 'arrowTop' : 'arrowDown'}
                size="0.75rem"
              />
            </CustomButton>
          </CategoryHeader>
          {category_name === 'toxy' && isExpanded && (
            <CustomScrollbar
              noScrollY={true}
              noScrollX={false}
              style={{
                height: '2.75rem',
              }}
              disableTrackXWidthCompensation={true}
              className="margin--12-bottom"
            >
              <CategorySort
                isExpanded={isExpanded}
                className="hidden-for-print"
              >
                {toxySortConfig.map(({ name, value }, key) => {
                  return (
                    <CategorySortItem
                      active={toxySortType === value}
                      key={key}
                      isFullMode={isFullMode}
                      onClick={() => handleChangeSortType(value)}
                    >
                      {t(name)}
                    </CategorySortItem>
                  )
                })}
              </CategorySort>{' '}
            </CustomScrollbar>
          )}
          <CategoryContent
            isExpanded={isExpanded}
            className="molecule-category-content"
          >
            {innerFiltered.map(
              ({ category_id, type, name, category_title }) => {
                if (name === 'synonyms') {
                  return (
                    <Synonyms key={category_id}>
                      <SynonymsTypeSelector>
                        {SYNONYMS_TYPES.map((st) => (
                          <CustomTab
                            key={st}
                            onClick={() => setSynonymsType(st)}
                            type={isFullMode ? 'grey' : 'white'}
                            size={'small'}
                            isActive={synonymsType === st}
                          >
                            {t(`molviewer.synonyms_types.${st}`)}
                          </CustomTab>
                        ))}
                      </SynonymsTypeSelector>
                      {!!data[name]?.[synonymsType]?.length && (
                        <SynonymsList>
                          {data[name][synonymsType].map((synonym, i) => (
                            <TextMedium
                              key={i}
                              fontWeight={theme.fontWeight.medium}
                            >
                              {synonym}
                            </TextMedium>
                          ))}
                        </SynonymsList>
                      )}
                    </Synonyms>
                  )
                }

                if (name === 'external') {
                  return (
                    <Fragment key={category_id}>
                      {data[name].map(({ value, view, link }, i) => (
                        <KeyValueProperty
                          className="molecule-key-value-property"
                          key={i}
                        >
                          <Subheadline
                            color={theme.colors.text.secondary}
                            fontWeight={400}
                          >
                            {view}
                          </Subheadline>
                          <ExternalLink
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Subheadline fontWeight={400}>{value}</Subheadline>
                            <Icon iconType={'link'} />
                          </ExternalLink>
                        </KeyValueProperty>
                      ))}
                    </Fragment>
                  )
                }

                if (type === 'headers') {
                  const headers = getDataByCategoryName(name).filter(
                    ({ value }) =>
                      value !== 'error' &&
                      !value?.find(
                        ({ type, text }) =>
                          type === 'header' &&
                          HEADERS_EXCEPTIONS.headers.includes(text)
                      )
                  )

                  if (headers?.length) {
                    return (
                      <GroupedProperties key={category_id}>
                        {headers.map(({ value }, i) => {
                          const header = value.find(
                            ({ type }) => type === 'header'
                          )
                          const lines = value.filter(
                            ({ type }) => type !== 'header'
                          )

                          return (
                            <Group key={i}>
                              {(header ? [header, ...lines] : lines).map(
                                ({ type, text, units, value, color }, i) => (
                                  <KeyValueProperty
                                    className="molecule-key-value-property"
                                    key={i}
                                  >
                                    {HEADERS_EXCEPTIONS.descriptions.includes(
                                      text
                                    ) ? (
                                      <div>
                                        <DescriptedPropertyKey
                                          data-tooltip-id={text}
                                          className={
                                            'property-name molecule-descripted-key'
                                          }
                                          status={
                                            type === 'header'
                                              ? color
                                              : undefined
                                          }
                                        >
                                          <ColoredSubheadline
                                            color={theme.colors.text.secondary}
                                            fontWeight={400}
                                            status={
                                              type === 'header'
                                                ? color
                                                : undefined
                                            }
                                          >
                                            {t(`molviewer.${text}`)}
                                          </ColoredSubheadline>
                                          <Icon iconType={'description'} />
                                        </DescriptedPropertyKey>
                                        <ReactTooltip
                                          id={text}
                                          clickable={true}
                                          delayHide={100}
                                          className="c-tooltip c-tooltip-base c-tooltip-z-max"
                                          classNameArrow="c-tooltip-arrow"
                                          place={'bottom'}
                                          positionStrategy={'fixed'}
                                          style={{ whiteSpace: 'pre-wrap' }}
                                        >
                                          {getCustomHeadersDescription(text)}
                                        </ReactTooltip>
                                      </div>
                                    ) : (
                                      <ColoredSubheadline
                                        color={'#616E7C'}
                                        status={
                                          type === 'header' ? color : undefined
                                        }
                                      >
                                        {t(`molviewer.${text}`)}
                                      </ColoredSubheadline>
                                    )}
                                    {value !== undefined && (
                                      <ColoredSubheadline
                                        color={'#1F2933'}
                                        status={color}
                                      >
                                        {value === 'not included'
                                          ? t(`molviewer.not_included`)
                                          : value}
                                        {units &&
                                          !HEADERS_EXCEPTIONS.units.includes(
                                            units
                                          ) &&
                                          ` ${t(`molviewer.${units}`)}`}
                                      </ColoredSubheadline>
                                    )}
                                  </KeyValueProperty>
                                )
                              )}
                            </Group>
                          )
                        })}
                      </GroupedProperties>
                    )
                  }
                }

                if (!type) {
                  const value = getDataByCategoryName(name)
                  const source = getSourceByCategoryName(name, sources)

                  if (needHideProperty(name, value)) return null

                  const hideCopyIconOnError =
                    Object.prototype.hasOwnProperty.call(
                      POSSIBLE_ERR_VALUES_BY_GROUP,
                      name
                    ) &&
                    POSSIBLE_ERR_VALUES_BY_GROUP[name]?.some(
                      (val) => val === value
                    )

                  const isBlurable =
                    isApplicabilityShow &&
                    !!applicabilityData?.[category_title] &&
                    typeof applicabilityData?.[category_title] === 'string' &&
                    source?.type !== 'exp'

                  return (
                    <PropertyRow key={category_id}>
                      <KeyValueProperty className="molecule-key-value-property">
                        {CATEGORIES_WITH_DESC.includes(name) ? (
                          <div>
                            <DescriptedPropertyKey
                              data-tooltip-id={`${category_id.toString()}-${name}`}
                              className={
                                'property-name molecule-descripted-key'
                              }
                            >
                              <Subheadline
                                color={theme.colors.text.secondary}
                                fontWeight={400}
                              >
                                {t(getCategoryKey(name))}
                              </Subheadline>
                              <Icon iconType={'description'} />
                            </DescriptedPropertyKey>

                            <ReactTooltip
                              id={`${category_id.toString()}-${name}`}
                              clickable={true}
                              delayHide={100}
                              className="c-tooltip c-tooltip-base c-tooltip-z-max"
                              classNameArrow="c-tooltip-arrow"
                              place={'bottom'}
                              positionStrategy={'fixed'}
                            >
                              {getCustomHeadersDescription(name)}
                            </ReactTooltip>
                          </div>
                        ) : (
                          <Subheadline
                            color={theme.colors.text.secondary}
                            fontSize="0.75rem"
                            fontWeight={400}
                            className={'property-name'}
                          >
                            {t(getCategoryKey(name))}
                          </Subheadline>
                        )}
                        <PropertyValue
                          name={name}
                          className={`property-value`}
                          onClick={() => {
                            isBlurable &&
                              blurNonApplicable &&
                              dispatch(setBlurNonApplicable(false))
                          }}
                        >
                          {value ? (
                            isBlurable && blurNonApplicable ? (
                              <BlurableValue
                                {...{
                                  name,
                                  value,
                                  isBlurable,
                                  blurNonApplicable,
                                }}
                              />
                            ) : (
                              <ValueWithTooltip {...{ name, value }} />
                            )
                          ) : (
                            <Icon
                              iconType="loader"
                              className="loader-icon"
                              size="1rem"
                            />
                          )}
                          {COPIED_PROPERTY_NAMES.includes(name) &&
                            !hideCopyIconOnError && (
                              <Icon
                                iconType="copy"
                                onClick={() => handleCopy(name, value)}
                                size="1rem"
                              />
                            )}
                          {source && (
                            <>
                              <PropertySource
                                data-tooltip-id={`${category_id.toString()}-${name}-ds`}
                                withTooltip={!!source?.source}
                              >
                                {source.type}
                              </PropertySource>
                              <ReactTooltip
                                id={`${category_id.toString()}-${name}-ds`}
                                className="c-tooltip c-tooltip-base c-tooltip-z-max"
                                classNameArrow="c-tooltip-arrow"
                                place={'bottom'}
                                hidden={!source?.source}
                                offset={8}
                                positionStrategy={'fixed'}
                              >
                                <Subheadline color={'#FFFFFF'}>
                                  {source.source}
                                </Subheadline>
                              </ReactTooltip>
                            </>
                          )}{' '}
                          {isApplicabilityShow &&
                            !!applicabilityData?.[category_title] &&
                            source?.type !== 'exp' && (
                              <ApplicabilityIndicator
                                number={
                                  typeof applicabilityData?.[category_title] ===
                                  'string'
                                    ? null
                                    : applicabilityData?.[category_title]
                                }
                              />
                            )}
                        </PropertyValue>
                      </KeyValueProperty>
                    </PropertyRow>
                  )
                }

                return null
              }
            )}
          </CategoryContent>
        </Container>
      </CustomBtnsScroller>
    </Wrapper>
  )
}
