import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import MoleculeViewer from 'components/MoleculeViewer'
import { Caption, Subheadline } from 'components/common/text/index.style'

import {
  Row,
  Card,
  CardFooter,
  SearchText,
  StyledButton,
  CardImageWrapper,
} from './index.style'
import { Column } from '../index.style'
import { getSearchTypeName } from './utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { openSearchHistory } from 'store/actions/searchHistory'
import CustomTag from 'components/common/customTag'
import { imageByTypeConfig } from './config'

const HistoryCard = ({
  time,
  type,
  smiles,
  method,
  searchText,
  onRepeatSearch,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showHistory = useSelector((store) => store.searchHistory.showHistory)

  return (
    <Card
      data-test="history-card"
      onClick={() => {
        showHistory && dispatch(openSearchHistory(false))
        onRepeatSearch(true)
      }}
    >
      <Column gap="1rem">
        <Row>
          <CustomTag>
            <Caption color="inherit">{getSearchTypeName(type, t)}</Caption>
          </CustomTag>
          <CustomTag>
            <Caption>{time}</Caption>
          </CustomTag>
        </Row>
        {searchText ? (
          <SearchText>{searchText}</SearchText>
        ) : (
          <MoleculeViewer
            smiles={smiles}
            handleClick={() => {}}
            onShowProps={() => {}}
            withText={false}
            noMenu
            noDelete
            noEdit
            fixedSize
            width={250}
            height={124}
            noHover
            noContainerHover={true}
            noContextMenu
            method={method}
            handleClickFromHistory={() => {
              showHistory && dispatch(openSearchHistory(false))
              onRepeatSearch(true)
            }}
          />
        )}
      </Column>
      {searchText && (
        <CardImageWrapper>
          <Icon
            iconType={imageByTypeConfig?.[type] ?? 'structureImage'}
            size="100%"
          />
        </CardImageWrapper>
      )}
      <CardFooter>
        <StyledButton
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            showHistory && dispatch(openSearchHistory(false))
            onRepeatSearch()
          }}
        >
          <Icon iconType="refresh" size="1rem" />
          <Subheadline data-test="history-repeat-search-text">
            {t('search.search_history.repeat')}
          </Subheadline>
        </StyledButton>
      </CardFooter>
    </Card>
  )
}

export default HistoryCard
