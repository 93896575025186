import { memo } from 'react'
import { CustomTagElement } from './index.style'

const CustomTag = memo(
  ({
    onClick = () => {},
    children,
    className,
    type = 'grey',
    width = '',
    height = '',
    withInteraction = false,
    defaultColor = '',
    defaultPadding = '',
    withBorder = true,
    ...props
  }) => (
    <CustomTagElement
      onClick={onClick}
      type={type}
      className={className}
      width={width}
      height={height}
      withInteraction={withInteraction}
      defaultColor={defaultColor}
      defaultPadding={defaultPadding}
      padding={props.padding}
      radius={props.radius}
      fontSize={props.fontSize}
      {...props}
    >
      {children}
    </CustomTagElement>
  )
)

CustomTag.displayName = 'CustomTag'
export default CustomTag
