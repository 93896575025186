import reactReplace from 'react-string-replace'
import i18n from 'i18n'
import { LinkRenderer } from '../LinkRenderer'
import { getArticleLink } from '../category/utils/utils'

export const getMainId = (id, match, smiles) => {
  if (smiles) return smiles
  if (match?.params?.smiles) return decodeURIComponent(match.params.smiles)
  return id
}

export const refButtonsConfig = [
  {
    name: 'molviewer.literature',
    disabled: false,
    handler: 'literature',
  },
  {
    name: 'molviewer.to_reactions',
    disabled: true,
    handler: 'reactions',
  },
]

const HIDDEN_PRED_CATEGORIES = ['Drug-likeness', 'Synthesis']

export const filterCategories = (categories, data, isNeedHidePred = false) => {
  if (!categories?.length || !data) return []

  return categories
    .filter(({ category_title }) => {
      switch (category_title) {
        case 'Synonyms':
          return !!data?.synonyms

        case 'Drug-likeness':
          return !!data?.drug

        case 'External DBs':
          return !!data?.external?.length

        default:
          return true
      }
    })
    .filter(({ category_title }) =>
      isNeedHidePred ? !HIDDEN_PRED_CATEGORIES.includes(category_title) : true
    )
}

export const getCategoryKey = (title) =>
  `molviewer.${title.toLowerCase().replace(/\s+/g, '_')}`

export const COPIED_PROPERTY_NAMES = [
  'smiles',
  'iupac',
  'baseID',
  'inchi',
  'inchiKey',
]

export const POSSIBLE_ERR_VALUES_BY_GROUP = {
  iupac: [
    'Невозможно создать корректное имя IUPAC',
    'Unable to generate valid IUPAC name',
  ],
}

export const getSourceByCategoryName = (name, sources) => {
  if (!sources) return ''
  return sources[name]?.type === 'exp' ? sources[name] : ''
}

export const addSubTags = (str) => {
  return reactReplace(str, /(\d+)/g, (match, i) => <sub key={i}>{match}</sub>) //todo: think about alternative
}

export const WARNING_PROPERTIES_VALUES = ['Positive ', 'Inhibitor ']

export const DEFAULT_EXPANDED_CATEGORY_ID = 1

export const HEADERS_EXCEPTIONS = {
  units: ['donors', 'acceptors', 'bonds', 'rings', 'atoms'],
  descriptions: [
    'TPSA <= 140 Å²',
    'PAINS',
    'QED - quantitative estimate of drug-likeness',
  ],
  headers: [
    'Topological polar surface area (including sulphur and phosphorus)',
  ],
}

export const getCustomHeadersDescription = (name) => {
  if (name === 'QED - quantitative estimate of drug-likeness') {
    return (
      <div style={{ textAlign: 'center' }}>
        <div>{i18n.t(`molviewer.tooltip.${name}`)}</div>
        <LinkRenderer href={'https://www.nature.com/articles/nchem.1243'}>
          {i18n.t('molviewer.tooltip.article')}
        </LinkRenderer>
      </div>
    )
  } else if (CATEGORIES_WITH_DESC.includes(name)) {
    const link = getArticleLink(name)

    if (DESC_WITH_GOST.includes(name)) {
      return (
        <div>
          {i18n.t('molviewer.tooltip.gost')}
          <LinkRenderer href={link}>{name.split(' ').slice(-1)}</LinkRenderer>
        </div>
      )
    }

    return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <div>{i18n.t(`molviewer.tooltip.${name}`)}</div>
        {!DESC_WITHOUT_ARTICLE.includes(name) && (
          <LinkRenderer href={link}>
            {i18n.t('molviewer.tooltip.article')}
          </LinkRenderer>
        )}
      </div>
    )
  }

  return i18n.t(`molviewer.tooltip.${name}`)
}

export const DIALOG_MODE_SIZE = 400

export const CATEGORIES_ID = 'categories'

export const scrollToCategory = (id) => {
  const currentCategory = document.getElementById(getCategoryId(id))

  if (currentCategory) {
    currentCategory.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}

export const getCategoryId = (id) => `category-${id}`

export const needHideProperty = (name, value) =>
  name === 'iupac' && value === 'Unable to generate valid IUPAC name '

export const DESC_WITH_GOST = [
  'Acute Aquatic Toxicity 57455-2017',
  'Acute Toxicity Swallowed 56957-2016',
  'Selective Target-Organ Or System Toxicity Single Exposure 58479-2019',
]

export const DESC_WITHOUT_ARTICLE = [
  'CYP1A2',
  'CYP2C19',
  'CYP2C9',
  'CYP2D6',
  'CYP3A4',
  'qsar_dmso_solubility',
  'qsar_retention_time',
  'qsar_refractive_index',
]

export const CATEGORIES_WITH_DESC = [
  'scscore_syba',
  'scscore_comp',
  ...DESC_WITH_GOST,
  ...DESC_WITHOUT_ARTICLE,
]
