import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import { useTheme } from 'styled-components'
import { getSurvey } from 'store/actions/survey'
import CustomSelect from 'components/CustomSelect'
import LoaderRestyling from 'components/LoaderRestyling'
import CustomButton from 'components/common/customButton'
import { Caption } from 'components/common/text/index.style'
import CustomTextArea from 'components/common/customTextArea'
import { FooterButtons } from 'components/RegistrationPageNew/index.style'

import {
  PARAMS_CONFIG,
  COMPANY_SIZE_OPTIONS,
  ANSWER_MAX_LETTERS_COUNT,
} from './config/config'
import InfoWithProgress from '../InfoWithProgress'
import { Container, FieldWithHeader } from './index.style'

const Questionnaire = ({
  loading,
  surveyData,
  progress,
  onGoToNextStep,
  onGoBack,
  onChange,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { loading: questionnaireLoading, surveys } = useSelector(
    (state) => state.survey
  )

  const answers = surveyData?.answers || []

  const survey = surveys?.find((el) => el.on_registration)

  const renderConfig =
    survey?.questions?.map((q, i) => ({
      ...q,
      ...PARAMS_CONFIG[i],
    })) || []

  useEffect(() => {
    if (!surveys) dispatch(getSurvey())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (survey && !surveyData?.survey_id)
      onChange({
        survey_id: survey.id,
        answers: survey.questions.map(({ id, text }) => ({
          question_id: id,
          text: text === 'company_size' ? COMPANY_SIZE_OPTIONS[0] : '',
        })),
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey])

  const handleChange = (question_id, value) => {
    onChange({
      ...surveyData,
      answers: answers.map((el) =>
        el.question_id === question_id ? { ...el, text: value } : el
      ),
    })
  }

  return (
    <>
      {!loading && (
        <InfoWithProgress
          progress={progress}
          title={t('registration.user_profile')}
          description={t('registration.provide_information_about_company')}
        />
      )}

      <Container>
        {loading || questionnaireLoading ? (
          <LoaderRestyling />
        ) : (
          renderConfig.map(
            ({ text, id, type, placeholder, withSearch, options }) => {
              const value = answers.find((el) => el.question_id === id)?.text

              return (
                <FieldWithHeader key={id}>
                  <Caption
                    color={theme.colors.text.secondary}
                    lineHeight={theme.text[14]}
                    fontWeight={theme.fontWeight.medium}
                  >
                    {t(`registration.${text}`)}
                    {' *'}
                  </Caption>
                  {type === 'select' ? (
                    <CustomSelect
                      width="100%"
                      withWhiteInputBackground
                      withSearchByLabel={withSearch}
                      inputPlaceholder={t('dataset_table.search')}
                      options={options.map((option) => ({
                        value: option,
                        label: `registration.${text}_options.${option}`,
                      }))}
                      selectedValue={{
                        value,
                        label: `registration.${text}_options.${value}`,
                      }}
                      onChange={(option) => handleChange(id, option.value)}
                      emptyLabel="registration.select_activity"
                    />
                  ) : (
                    <Container gap="0.5rem">
                      <CustomTextArea
                        placeholder={t(placeholder)}
                        onChange={(v) =>
                          v.length <= ANSWER_MAX_LETTERS_COUNT &&
                          handleChange(id, v)
                        }
                        value={value}
                        withClearButton
                      />

                      <Caption color={theme.colors.text.tertiary}>
                        {`${value?.length} / ${ANSWER_MAX_LETTERS_COUNT}`}
                      </Caption>
                    </Container>
                  )}
                </FieldWithHeader>
              )
            }
          )
        )}
      </Container>

      {!loading && (
        <FooterButtons>
          <CustomButton
            type="text"
            gap="0.25rem"
            onClick={onGoBack}
            data-test="go-back-btn"
          >
            <Icon iconType="arrowLeft" size="1rem" />
            {t('registration.back')}
          </CustomButton>
          <CustomButton
            type="text_accent"
            data-tooltip-id="disabled-next-button"
            disabled={progress < 150 || questionnaireLoading}
            gap="0.25rem"
            onClick={onGoToNextStep}
            data-test="complete-registration-btn"
          >
            {t('registration.complete_registration')}
            <Icon iconType="arrowRight" size="1rem" />
          </CustomButton>
        </FooterButtons>
      )}

      {progress < 150 && (
        <ReactTooltip
          id="disabled-next-button"
          className="c-tooltip c-tooltip-base"
          classNameArrow="c-tooltip-arrow"
          place={'top-end'}
        >
          {t('registration.fill_all_fields')}
        </ReactTooltip>
      )}
    </>
  )
}

export default Questionnaire
