import { takeEvery, put, select } from 'redux-saga/effects'
import axios from 'axios'
import {
  SPECTRA_MS_FAIL,
  SPECTRA_MS_SUCCESS,
  SPECTRA_MS_INIT,
  SPECTRA_NMR_SUCCESS,
  SPECTRA_NMR_FAIL,
  SPECTRA_NMR_INIT,
  SPECTRA_INFRARED_INIT,
  SPECTRA_INFRARED_SUCCESS,
  SPECTRA_INFRARED_FAIL,
} from 'store/constants/spectra'
import { API_URL } from 'config/config'
import { getHeaders } from 'services/rest'

function* spectraMsGet({
  data: { smiles, adduct_type, m_z_round, percent_round },
}) {
  try {
    const msResponse = yield axios.post(
      `${API_URL}predict-cfmid`,
      {
        smiles,
        adduct_type,
      },
      {
        headers: getHeaders(),
      }
    )

    const isotopsResponse = yield axios.post(
      `${API_URL}run-task`,
      {
        service: 'isotops',
        params: { smiles, m_z_round, percent_round },
      },
      {
        headers: getHeaders(),
      }
    )

    yield put({
      type: SPECTRA_MS_SUCCESS,
      data: {
        ...msResponse.data,
        isotopic_distribution: [...isotopsResponse.data.result],
      },
    })
  } catch (error) {
    yield put({
      type: SPECTRA_MS_FAIL,
      error: error,
    })
  }
}

function* spectraNmrGet({ data: { smiles, method } }) {
  try {
    const { data } = yield axios.post(
      `${API_URL}run-task`,
      {
        input: {
          smiles,
        },
        service: 'nmr-hosegen',
        nocache: false,
        type: 'instant',
        method,
      },
      {
        headers: getHeaders(),
      }
    )

    yield put({
      type: SPECTRA_NMR_SUCCESS,
      data: {
        ...data.result,
      },
    })
  } catch (error) {
    yield put({
      type: SPECTRA_NMR_FAIL,
      error: error,
    })
  }
}

function* spectraInfraredGet({ smiles }) {
  try {
    const method = yield select(
      (state) => state.spectra.infrared.shootingMethod
    )
    const { data } = yield axios.post(
      `${API_URL}run-task`,
      {
        service: 'ir_spectr',
        params: { smiles, adduct_type: method.value },
      },
      {
        headers: getHeaders(),
      }
    )

    yield put({
      type: SPECTRA_INFRARED_SUCCESS,
      data: [...data.result],
    })
  } catch (error) {
    yield put({
      type: SPECTRA_INFRARED_FAIL,
      error: error,
    })
  }
}

export function* spectraMsGetWatcher() {
  yield takeEvery(SPECTRA_MS_INIT, spectraMsGet)
}

export function* spectraNmrGetWatcher() {
  yield takeEvery(SPECTRA_NMR_INIT, spectraNmrGet)
}

export function* spectraInfraredGetWatcher() {
  yield takeEvery(SPECTRA_INFRARED_INIT, spectraInfraredGet)
}
