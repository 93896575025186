import { API_URL } from 'config/config'

export const getCountNoun = (count) => {
  const remainder = count % 10

  if (remainder === 1) return 'one'
  if (remainder > 0 && remainder <= 4) return 'few'
  return 'many'
}

export const DOCUMENTS_CONTAINER_ID = 'pdf2smiles-documents'

export const PDF2SMILES_UPLOAD_URL = `${API_URL}${'pdf2smiles/create_document'}`
export const PDF2SMILES_UPLOAD_HEADERS = {
  'Content-type': 'multipart/form-data',
}
export const PDF2SMILES_GET_DOCS_URL = `${API_URL}${'pdf2smiles/documents_list'}`
export const PDF2SMILES_UPDATE_DOCUMENT_URL = `${API_URL}${'pdf2smiles/update_document'}`
export const PDF2SMILES_DELETE_DOCUMENT_URL = `${API_URL}${'pdf2smiles/delete_document'}`
export const PDF2SMILES_DOWNLOAD_MARKUP_URL = `${API_URL}${'pdf2smiles/export_markup'}`
export const PDF2SMILES_DOWNLOAD_MARKUP_OPTIONS = {
  responseType: 'blob',
}

export const PDF2SMILES_DOWNLOAD_MARKUP_EXT = {
  csv: 'csv',
  png_zip: 'zip',
}

export const PDF2SMILES_UPLOAD_D0C_SUCCESS =
  'notification.file_uploaded_successfully'
export const PDF2SMILES_UPLOAD_D0C_ONLY_ONE =
  'You can recognize 1 document at a time'
