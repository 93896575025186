import { useCallback, useEffect, useRef } from 'react'
import { isNotificationClicked } from './utils'

export const OutboundClick = ({
  children,
  onClick,
  outboundClickEnabled = true,
  exceptions,
  onEsc = true,
  outBoundId,
  isPropagate = true,
  className,
}) => {
  const ref = useRef(null)

  const handleClickOutside = useCallback(
    (event) => {
      if (!ref.current) return
      const tag = event.target?.tagName
      const target =
        tag && tag === 'path'
          ? event.target?.closest('svg') || event.target
          : event.target

      const isOutside = (ref) => !ref.contains(target)

      const ketcherMenuSvgClicked =
        outBoundId &&
        outBoundId === 'ketcher' &&
        exceptions?.some((item) => {
          return (
            (typeof target?.className === 'string' &&
              target.className?.includes(item)) ||
            (typeof target?.parentElement?.className === 'string' &&
              target.parentElement.className?.includes(item))
          )
        })

      if (isNotificationClicked(event)) return

      if (exceptions && exceptions.length) {
        if (
          isOutside(ref.current) &&
          !exceptions.some((item) => {
            return (
              target.classList.contains(item) ||
              target.closest(`.${item}`) ||
              ketcherMenuSvgClicked
            )
          })
        ) {
          onClick()
          !isPropagate && event.stopPropagation()
        }
      } else if (isOutside(ref.current)) {
        onClick()
        !isPropagate && event.stopPropagation()
      }
    },
    [onClick, exceptions]
  )

  const escFunction = useCallback(
    (e) => {
      if (e.key === 'Escape') onEsc && onClick()
    },
    [onClick, onEsc]
  )

  useEffect(() => {
    if (outboundClickEnabled) {
      document.addEventListener('click', handleClickOutside, true)
      document.addEventListener('contextmenu', handleClickOutside, true)
      document.addEventListener('keydown', escFunction, true)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('contextmenu', handleClickOutside, true)
      document.addEventListener('keydown', escFunction, true)
    }
  }, [handleClickOutside, escFunction, outboundClickEnabled])

  if (!outboundClickEnabled) {
    return <>{children}</>
  }

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
}
